import {useContext, useState,useEffect,useRef} from 'react';
import { LanguageContext } from "../../../componentes/providers/LanguageProvider";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import ImageCropImpl from '../../../componentes/cropImage/ImageCropImpl';
import AuthContext from '../../../componentes/authProvider/AuthContext';
import IUser from '@/../../cgo_commons/types/users/IUser';
import { Controller, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import ModalLocation from './ModalLocation';
import { getFormErrorMessage, isNull } from '../../../utils/Utils';
import { DeviceContext } from '../../../componentes/providers/DeviceProvider';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import {genderOptions,sexualPreferencesOptions} from '@../../../../cgo_commons/utilities/SelectOptions'

const imagen = require("../../../assets/imgs/foto-perfil.jpg");

interface Props {
  
}

interface LocalIUser extends IUser {
    
}
const TabProfile : React.FC<Props> = ({}) => {
    const address1Ref                                       = useRef<HTMLInputElement>();
    const [checked, setChecked]                             = useState(false);
    const [visibleEditThumb, setVisibleEditThumb]           = useState<boolean>(false);
    const [visibleLocation, setVisibleLocation]           = useState<boolean>(false);
    const { language, translations }                        = useContext(LanguageContext);
    const { userLogued, reloadUserLogued }                  = useContext(AuthContext);
    const { isMobile }                                      = useContext(DeviceContext);
    const [path,setPath]                                    = useState<string>(imagen);
    const listGenderOptions                                 = genderOptions();
    const listSexualPreferencesOptions                      = sexualPreferencesOptions();
    const { reset,trigger,control,register, handleSubmit, watch,setValue, formState: { errors } } = useForm<LocalIUser>();


    

    const setImagePath = () => {
        setPath(process.env.REACT_APP_URL_BACKEND + (userLogued?.thumbnail || ''));
    }
    useEffect(() => {
        setImagePath();
    },[]);

    useEffect(() => {
        setImagePath();
        if (userLogued != undefined && !isNull(userLogued.city)  && !isNull(userLogued.state) && address1Ref && address1Ref.current) {
            address1Ref.current.value = userLogued.city + ", " + userLogued.state + ", " + userLogued.country;
        }
    },[userLogued])

    const handleChangeThumbnail = () => {
        
        setVisibleEditThumb(true);
    }
    const handleLocationInfo = () => {
        setVisibleLocation(true);
    }
    return <>
        <div className=" surface-section surface-card p-5 pt-1 shadow-2 border-round flex-auto xl:ml-2">
            <div className="text-900 font-semibold text-lg mt-3">
                {translations.my_account}
            </div>
            <ImageCropImpl setVisible={setVisibleEditThumb} visible={visibleEditThumb} />
            <ModalLocation visible={visibleLocation} setVisible={setVisibleLocation}/>
            <Divider></Divider>
            <div className="flex gap-5 flex-column-reverse md:flex-row">
                <div className="flex-auto p-fluid">
                    <div className="mb-4">
                        <label
                            htmlFor="email"
                            className="block font-normal text-900 mb-2"
                        >
                            {translations.profile_name}
                        </label>
                        <InputText id="email" />
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="bio"
                            className="block font-normal text-900 mb-2"
                        >
                            {translations.profile_about_you}
                        </label>
                        <InputTextarea
                            id="bio"
                            rows={5}
                            autoResize={true}
                        ></InputTextarea>
                        {/*<p className="mt-2 mb-0 font-medium text-base text-600">
                            You can{" "}
                            <span className="font-medium text-900">
                                @mention
                            </span>{" "}
                            other users and
                            organizations to link to
                            them.
</p>*/}
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="genero"
                            className="block font-normal text-900 mb-2"
                        >
                            {translations.profile_gender}
                        </label>
                        <Controller
                            name="genero"
                            control={control}
                            rules={{ required: translations.required }}
                            render={({ field, fieldState }) => (
                                    <Dropdown
                                        id={field.name}
                                        value={field.value}
                                        optionLabel="text"
                                        placeholder={translations.profile_gender}
                                        options={listGenderOptions}
                                        focusInputRef={field.ref}
                                        onChange={(e) => field.onChange(e.value)}
                                        className={classNames('w-full',{ 'p-invalid': fieldState.error })}
                                    />
                            )}
                        />
                        {getFormErrorMessage(errors,'genero')}
                        
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="sexPreferencias"
                            className="block font-normal text-900 mb-2"
                        >
                            {translations.profile_are_you_searching}
                        </label>
                        <Controller
                    name="sexPreferencias"
                    control={control}
                    rules={{ required: translations.required }}
                    render={({ field, fieldState }) => (
                            <Dropdown
                                id={field.name}
                                value={field.value}
                                optionLabel="text"
                                placeholder={translations.profile_are_you_searching}
                                options={listSexualPreferencesOptions}
                                focusInputRef={field.ref}
                                onChange={(e) => field.onChange(e.value)}
                                className={classNames('w-full',{ 'p-invalid': fieldState.error })}
                            />
                    )}
                />
                {getFormErrorMessage(errors,'sexPreferencias')}
                        
                    </div>
                    <div className="flex flex-column md:flex-row">
                        <div className="flex-auto p-fluid">
                            <div className="lg:mb-4 md:mb-4">
                                <label
                                    htmlFor="ubicacion"
                                    className="block font-normal text-900 mb-2"
                                >
                                    Ciudad donde vives
                                </label>
                                <InputText id="ubicacion1" ref={address1Ref as any} placeholder='Ciudad donde vives' readOnly/>
                            </div>
                            
                        </div>
                        <div className="flex flex-column align-items-right flex-or lg:ml-2 md:ml-2">
                            <div className="mb-4">
                            <Button className="w-auto " style={!isMobile ? {marginTop:"26px"} : {marginTop:"5px"}} onClick={handleLocationInfo}><FontAwesomeIcon icon={faLocationDot} />&nbsp;
                            {userLogued && userLogued.city != null ? 'Cambiar Ubicación' : 'Establecer mi Ubicación'}
                            </Button>
                            </div>
                        </div>
                    </div>
                    
                    <div className="mb-4">
                        <label
                            htmlFor="visibility"
                            className="block font-normal text-900 mb-2"
                        >
                            Profile Visibility
                        </label>
                        <div className="flex align-items-center">
                            <Checkbox
                                inputId="visibility"
                                onChange={(e) =>
                                    setChecked(
                                        e.checked as any
                                    )
                                }
                                checked={checked}
                            ></Checkbox>
                            <span className="ml-2 font-normal text-base text-color-primary">
                                Make profile private and
                                hide all activity
                            </span>
                        </div>
                    </div>
                    <div>
                        <Button
                            label="Update Profile"
                            className="w-auto mt-3"
                        ></Button>
                    </div>
                </div>
                <div className="flex flex-column align-items-center flex-or">
                    <span className="font-normal text-900 mb-2">
                        Profile Picture
                    </span>
                    <img
                        src={path}
                        className="w-10rem"
                    />
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded -mt-4"
                        onClick={handleChangeThumbnail}
                    ></Button>
                    
                </div>
            </div>
        </div>
    </>
}

export default TabProfile;