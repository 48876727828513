import React, { createContext, useEffect, useState,useRef } from "react";
import { ReactNode } from 'react';
import IUser from "../../../../cgo_commons/types/users/IUser";
import getAxiosInstance from "../../utils/AxiosUtil";
import { Toast } from 'primereact/toast';

interface AuthContextData {
    token: string | null;
    setTokenStorage: (token: string | null) => void;
    logout: () => void;
    userLogued: IUser | null;
    isUserInfoLoaded:boolean;
    isAuthenticated:boolean;
    reloadUserLogued: () => void;
    showToast: (a:IToastMessage) => void
  }
  interface AuthProviderProps {
    children: ReactNode;
  }
  export interface IToastMessage {
    severity?:'success'|'info'|'warn'|'error';
    summary?:string;
    detail:string;
    life?:number;
  }
  const AuthContext = createContext<AuthContextData>({
    token: null,
    setTokenStorage: (token: string | null) => {},
    logout: () => {},
    userLogued: null,
    isUserInfoLoaded:false,
    isAuthenticated:false,
    reloadUserLogued: () => {},
    showToast: (a:IToastMessage) => {}
  });
  
  export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [token, setToken]                       = useState<string | null>(localStorage.getItem('token'));
    const [userLogued,setUserLogued]              = useState<IUser>({} as IUser);
    const [isUserInfoLoaded,setIsUserInfoLoaded]  = useState<boolean>(false);
    const [isAuthenticated,setIsAuthenticated]    = useState<boolean>(false);
    const toast                                   = useRef<Toast>(null);

    const showToast = (msg : IToastMessage) => {
      toast.current?.show({severity:msg.severity ? msg.severity : 'success', summary: msg.summary ? msg.summary : '', detail:msg.detail, life: msg.life ? msg.life : 3000});
    }
  const fetchUserLogued = async (force?:boolean) => {
    if ((!userLogued.id && token) || force) {
      const response : {data:{data: IUser}} = await getAxiosInstance().get("/api/user/getUserLogued");
      response.data.data.thumbnail = response.data.data.thumbnail != null ? '/img/get/user_profiles/'+ response.data.data.id + '/' + response.data.data.thumbnail : '/img/get/-1/-1/foto-perfil.jpg'

      setUserLogued(response.data.data);
      setIsUserInfoLoaded(true);
    }
  }
  const reloadUserLogued = () => {
    fetchUserLogued(true);
  }
    useEffect(() => {
      fetchUserLogued();
    },[])

    useEffect(() => {
      fetchUserLogued();
    },[token])

    useEffect(() => {
      if (userLogued.age && userLogued.age>17) {
        setIsAuthenticated(true);
      }
    },[userLogued])

    const setTokenStorage = (a:string | null) => {
        setToken(a);
        localStorage.setItem('token',(a ? a : ''))
    }
    /*const [windowSize, setWindowSize]   = useState([
      window.innerWidth,
      window.innerHeight,
    ]);*/
    const logout = () => {
      localStorage.removeItem ('token')
      document.location.href = "/login";
    }

    
    return (
      <AuthContext.Provider value={{ token, setTokenStorage,logout,userLogued,isUserInfoLoaded,isAuthenticated,reloadUserLogued,showToast}}>
        <Toast ref={toast} />
        {children}
      </AuthContext.Provider>
    );
  };
  
  export default AuthContext;