import {useRef, useContext} from 'react';
import { Menu } from 'primereact/menu';
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import { Link, useNavigate } from "react-router-dom";
import { Badge } from "primereact/badge";
import { DeviceContext } from '../providers/DeviceProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
interface Props { 
    slider:any
}


const NavLeft: React.FC<Props> = ({slider}) => {
    const { isMobile }                          = useContext(DeviceContext);
    const btnRef40 = useRef(null);
    const btnRef41 = useRef(null);
    const navigate = useNavigate();
    const currentPath = window.location.pathname;
    const handleClick = () => {
        if (isMobile) {
            slider.current.classList.remove("hiddden");
            slider.current.classList.add("fadeoutleft");
            setTimeout(() => {
                slider.current.classList.add("hidden");
                slider.current.classList.remove("fadeoutleft");
            }, 100);
        }
        
    }
    const icon = {
        prefix: 'fas',
        iconName: 'comments-solid',
        icon: [
          512,
          512,
          [],
          `<path fill="currentColor" d="M496 0H16C7.2 0 0 7.2 0 16v288c0 8.8 7.2 16 16 16h96v144l192-144h208c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16zM464 288H224V32h240v256z"></path>`
        ]
      };
    return (<>
    <div className="mt-3">
        <ul className="list-none p-0 m-0">
            <li>
                <Link onClick={handleClick} to="/channels" style={{ textDecoration: 'none' }}  className={"p-ripple flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center  border-left-2   transition-duration-150 transition-colors " + (currentPath.includes('task') || currentPath === '/' ? " text-cyan-600 border-cyan-600" : " border-transparent text-600")}>
                <img src="/icons/comments-regular-gray.svg" alt="Descripción del archivo SVG" className="pi pi-list mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl widthIcons"  />
                    <span className="font-medium inline text-base lg:text-xs lg:block">Chat General</span>
                    <Ripple />
                </Link>
            </li>
            {/*<li>
            <Link to="/home" style={{ textDecoration: 'none' }} className="p-ripple flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors">
                    <i className="pi pi-search mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                    <span className="font-medium inline text-base lg:text-xs lg:block">Search</span>
                    <Ripple />

            </Link>
            </li>*/}
            <li>
            <Link onClick={handleClick}  to="/contactos" style={{ textDecoration: 'none' }} className={"p-ripple flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2  transition-duration-150 transition-colors" + (currentPath.includes('contactos')  ? " text-cyan-600 border-cyan-600" : " border-transparent text-600")}>
                    <i className="pi pi-users mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                    <span className="font-medium inline text-base lg:text-xs lg:block">Contactos</span>
                    <Ripple />
                </Link>
            </li>
            <li>
            <Link onClick={handleClick} to="/usuarios" style={{ textDecoration: 'none' }} className={"p-ripple flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 transition-duration-150 transition-colors" + (currentPath.includes('usuarios')  ? " text-cyan-600 border-cyan-600" : " border-transparent text-600")}>
                    <i className="pi pi-users mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                    <span className="font-medium inline text-base lg:text-xs lg:block">Usuarios</span>
                    <Ripple />
                </Link>
            </li>
            <li>
            <Link onClick={handleClick} to="/obras" style={{ textDecoration: 'none' }} className={"p-ripple flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 transition-duration-150 transition-colors" + (currentPath.includes('obras')  ? " text-cyan-600 border-cyan-600" : " border-transparent text-600")}>
                    <i className="pi pi-building mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                    <span className="font-medium inline text-base lg:text-xs lg:block">Obras</span>
                    <Ripple />
                </Link>
            </li>
            {/*<li className="relative">
                <StyleClass nodeRef={btnRef40} selector="@next" enterClassName="hidden" leaveToClassName="hidden" hideOnOutsideClick>
                    <a ref={btnRef40} className="p-ripple flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors" >
                        <i className="pi pi-chart-line mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl p-overlay-badge"><Badge severity="danger">3</Badge></i>
                        <span className="font-medium inline text-base lg:text-xs lg:block">Reports</span>
                        <i className="pi pi-chevron-down ml-auto lg:hidden"></i>
                        <Ripple />
                    </a>
                </StyleClass>
                <ul className="list-none pl-3 pr-0 py-0 lg:p-3 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out static border-round-right lg:absolute left-100 top-0 z-1 surface-overlay shadow-none lg:shadow-2 w-full lg:w-15rem">
                    <li>
                        <StyleClass nodeRef={btnRef41} selector="@next" toggleClassName="hidden">
                            <a ref={btnRef41} className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 hover:text-900 border-round text-600 hover:text-700 transition-duration-150 transition-colors">
                                <i className="pi pi-chart-line mr-2"></i>
                                <span className="font-medium">Revenue</span>
                                <i className="pi pi-chevron-down ml-auto"></i>
                                <Ripple />
                            </a>
                        </StyleClass>
                        <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                            <li>
                                <a className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 hover:text-900 border-round text-600 hover:text-700 transition-duration-150 transition-colors">
                                    <i className="pi pi-table mr-2"></i>
                                    <span className="font-medium">View</span>
                                    <Ripple />
                                </a>
                            </li>
                            <li>
                                <a className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 hover:text-900 border-round text-600 hover:text-700 transition-duration-150 transition-colors">
                                    <i className="pi pi-search mr-2"></i>
                                    <span className="font-medium">Search</span>
                                    <Ripple />
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a className="p-ripple flex align-items-center cursor-pointer p-3 hover:surface-100 hover:text-900 border-round text-600 hover:text-700 transition-duration-150 transition-colors">
                            <i className="pi pi-chart-line mr-2"></i>
                            <span className="font-medium">Expenses</span>
                            <Ripple />
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <a className="p-ripple flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors">
                    <i className="pi pi-calendar mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                    <span className="font-medium inline text-base lg:text-xs lg:block">Events</span>
                    <Ripple />
                </a>
            </li>
            <li>
                <a className="p-ripple flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors">
                    <i className="pi pi-cog mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"></i>
                    <span className="font-medium inline text-base lg:text-xs lg:block">Options</span>
                    <Ripple />
                </a>
            </li>*/}
        </ul>
    </div>
    </>);
}

export default NavLeft;