import {useContext, useState,useEffect,useRef} from 'react';

import IUser from '@/../../cgo_commons/types/users/IUser';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToCity, faCheck, faClose, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import externalApisAxiosInstance from '../../../componentes/axios/ExternalApisAxios';
import userAxiosInstance from '../../../componentes/axios/UserAxios';
import AuthContext from '../../../componentes/authProvider/AuthContext';
import UpdateFields from '@/../../cgo_commons/types/genericData/UpdateFields';
import InputAutocomplete from '../../../componentes/input/InputAutocomplete';
import IAddress from '@/../../cgo_commons/types/response/IAddress';
import useGooglePlaceAutoComplete from '../../../utils/useGooglePlaceAutoComplete';
import { DeviceContext } from '../../../componentes/providers/DeviceProvider';

interface Props {
  visible:boolean;
  setVisible: (a:boolean) => void;
}


const ModalLocation : React.FC<Props> = ({visible,setVisible} ) => {
    const [latitude, setLatitude]                       = useState<number | null>();
    const [longitude, setLongitude]                     = useState<number | null>();
    const { userLogued, reloadUserLogued,showToast }    = useContext(AuthContext);
    const [ciudadManual,setCiudadManual]                = useState<IAddress>();
    const [showManual,setShowManual]                    = useState<boolean>(false);
    const { isMobile }                                  = useContext(DeviceContext);
    const googleAutoCompleteSvc                         = useGooglePlaceAutoComplete(process.env.REACT_APP_GOOGLE_AUTOCOMPLETE || '',true);
    

    
    const getCurrentPosition = () => {
        navigator.geolocation.getCurrentPosition(
          position => {//-35.12382532059449, -60.223960394664395
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          error => {
            console.log('Error obteniendo la ubicación:', error);
          }
        );
      };
    const requestLocationPermission = async () => {
        const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
  
        if (permissionStatus.state === 'granted') {
          getCurrentPosition();
        } else if (permissionStatus.state === 'prompt') {
          
            getCurrentPosition();
          
        } else {
          console.log('Acceso a la ubicación denegado.');
        }
      };
      useEffect(() => {
        if (latitude != null && longitude != null) {
            getCityFromCoordinates();
        }
      },[latitude,longitude])
      const getCityFromCoordinates = async () => {
        if (latitude && longitude) {
          const apiKey = process.env.REACT_APP_GOOGLE_MAPS ? process.env.REACT_APP_GOOGLE_MAPS : ''; // Reemplaza con tu propia API key de Google Maps
  
          
          const address = await externalApisAxiosInstance.fetchPlacesByCoords(latitude,longitude,true);
          if (address) {

            if (userLogued?.id) {
                await updateUser(address,0);
                reloadUserLogued();
                showToast({detail:"Ubicación guardada"})
                setVisible(false);
            }
            
          }
        }
      };

      const handleConfirmManual = async () => {
        
        if (ciudadManual && ciudadManual.lat && ciudadManual.lng) {
          console.log(ciudadManual)
          await updateUser(ciudadManual,1,ciudadManual?.lat,ciudadManual.lng);
          reloadUserLogued();
          showToast({detail:"Ubicación guardada"})
          setVisible(false);
        }
        
      }
      const updateUser = async (address : IAddress,hasCoordManual:0|1,lat?:number,lng?:number) : Promise<void> => {
        if ((!hasCoordManual && latitude && longitude) || (hasCoordManual && lat && lng)) {
          const user: IUser & UpdateFields      = userLogued as any;
          user.city           = address.city;
          user.country        = address.country;
          user.state          = address.state;
          user.lat            = (hasCoordManual ? (lat ? lat : undefined) : (latitude ? latitude : undefined));
          user.lng            = (hasCoordManual ? (lng ? lng : undefined) : (longitude ? longitude : undefined));
          user.hasCoordManual = hasCoordManual;
          user.updateFields   = "location"
          await userAxiosInstance.update(user);
        }
        
      }
      const handleShow = () => {
        setLatitude(null);
        setLongitude(null);
        setShowManual(false);
      }
      const handleClickManual = () => {
        setCiudadManual(undefined);
        setShowManual(true);
        
      }
      const PanelMain = () => {
        return <>
        <div className="grid">
                <div className="col" >
                    <div style={!isMobile ? {height:"80px"} : {}} className="flex flex-wrap card-container">
                        <div className="flex align-items-center  ">
                            Debes seleccionar tu ciudad manualmente.
                        </div>
                        <div className="flex align-items-center">
                            La precisión de la proximidad con otras personas no es óptima.
                        </div>
                    </div>
                    <div className="flex justify-content-center flex-wrap card-container mt-4">
                        <div className="flex align-items-center justify-content-center">
                            <Button onClick={(e) => handleClickManual()}><FontAwesomeIcon icon={faArrowRightToCity} />&nbsp;Elegir mi Ciudad</Button>
                        </div>
                    </div>
                </div>
                <div className="col-fixed" style={{width:"50px"}}><Divider layout="vertical" /></div>
                <div className="col">
                    <div style={!isMobile ? {height:"80px"} : {}} className="flex flex-wrap card-container">
                        <div className="flex align-items-center  ">
                            Solicitaremos tu permiso para acceder a tu ubicación.
                        </div>
                        <div className="flex align-items-center">
                            Tu ubicación exacta permanecerá confidencial, solo se mostrará la ciudad
                        </div>
                    </div>
                    <div className="flex justify-content-center flex-wrap card-container mt-4">
                        <div className="flex align-items-center justify-content-center">
                            <Button onClick={requestLocationPermission}><FontAwesomeIcon icon={faMapLocationDot} />&nbsp;Automático</Button>
                        </div>
                    </div>
                
                </div>
            </div>
        </>
      }
      const PanelInputCiudad = () => {
        return <>
            <InputAutocomplete location={ciudadManual} setLocacion={setCiudadManual} googleAutoComplete={googleAutoCompleteSvc}/>
        </>
      }
      const footer = () => {
        return <>{ciudadManual && showManual && (
            <>
              <hr style={{border: "none",borderTop: "1px solid lightgray"}} className='mb-4'></hr>
              <Button severity='secondary' onClick={() => setVisible(false)}><FontAwesomeIcon icon={faClose}></FontAwesomeIcon>&nbsp;Cancelar</Button>
              <Button onClick={handleConfirmManual}><FontAwesomeIcon icon={faCheck} ></FontAwesomeIcon>&nbsp;Confirmar</Button>
              <Button onClick={requestLocationPermission} severity='info'><FontAwesomeIcon icon={faMapLocationDot}></FontAwesomeIcon>&nbsp;Automático</Button>
            </>
        )}</>
      }
    return (
    <>
    
        <Dialog header="Descubre personas cerca de ti" visible={visible} style={{ width: '50vw' }} onShow={handleShow} onHide={() => setVisible(false)} 
                breakpoints={{ '960px': '75vw', '641px': '100vw' }} footer={footer}>
            
                {!showManual && <PanelMain />}
                {showManual && <PanelInputCiudad />}
            </Dialog>
    </>
    )
}

export default ModalLocation;