
import PageTitle from '../../componentes/pageTitle/PageTitle';
import {useEffect, useRef, useState,useLayoutEffect, useContext} from 'react';
import { Chip } from 'primereact/chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faRectangleXmark } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { useLocation, useNavigate } from "react-router-dom";
import channelAxiosInstance from '../../componentes/axios/ChannelAxios';
import IChannel from '../../../../cgo_commons/types/channels/IChannel';
import ChannelFilter from '../../../../cgo_commons/filters/channels/ChannelFilter';
import { formatDate, formatTimeDifference } from '../../utils/Utils';
import ChipTag from '../../componentes/chats/chipTag/ChipTag';
import { LanguageContext } from '../../componentes/providers/LanguageProvider';
import ChatContext from '../../componentes/chats/context/ChatContext';
interface Props { }


const ListChannels: React.FC<Props> = props => {
    const navigate                                                      = useNavigate();
    const {search}                                                      = useLocation();
    const [localChannels,setLocalChannels]                              = useState<IChannel[]>([]);
    const { language, translations }                                    = useContext(LanguageContext);
    const {socket,isConnected,handleAddListener,handleRemoveListener,setChannel}   = useContext(ChatContext);
    const mb2 = "mb-2"
    const getFilter = (ifilter: ChannelFilter,first?:number,rows?:number) : ChannelFilter => {
        const queryParams = new URLSearchParams(search);
        const q = queryParams.get('q');//nombre del parametro
        const filter : ChannelFilter   = ifilter;
        filter.isUserLoguedConnected= 1;
        filter.currentPage          = (first ? first : 0);
        filter.pageSize             = (rows ? rows : 25)

        return filter;
    }
    const listChannels = async () => {
        //const a : ConstChannel = new ConstChannel();
        const list = await channelAxiosInstance.fetch(getFilter({idType:1,isActive:1} as any))
        console.log(list)
        setLocalChannels(list.data);
    }
    useEffect(() => {
        listChannels();
        return (() => {
            
        })
    },[]);


    useEffect(() => {
        if (isConnected) {
            handleAddListener('leave_channel_did',listChannels);
        }
        
        return () => {
            handleRemoveListener('leave_channel_did');
        };
    },[isConnected])

    /**
     * cambiar por el tipo de dato
     * @param element 
     */
    const handleClickChannel = (element : IChannel) => {
        const data = {
            channel: element.id,
            type:element.type,
            title:element.title
          };
          setChannel({id:element.id,type:element.type,title:element.title} as any)
          navigate("/chat", { state: data });
    }

    const handleClickChannelDisconnect = (c : IChannel) => {
        if (isConnected) {
            socket?.emit("leave",{channel:c.id,channelType:c.type})
        }
    }
    return <>
    <PageTitle titleMain='Chat' titleSecondary='Canales'></PageTitle>
    {/*<ul className="list-none p-0 m-0 flex font-medium overflow-x-auto" style={{ borderRadius: '30px' }}>
    <li className="bg-cyan-500 z-5">
        <a className="cursor-pointer bg-cyan-500 flex align-items-center justify-content-center px-5 py-3 shadow-2 h-full" style={{ borderTopRightRadius: '30px', borderBottomRightRadius: '30px' }}>
            <i className="pi pi-home text-white"></i>
        </a>
    </li>
    <li className="bg-cyan-500 z-4">
        <a className="cursor-pointer bg-cyan-500 flex align-items-center justify-content-center px-5 py-3 text-white white-space-nowrap shadow-2 h-full" style={{ borderTopRightRadius: '30px', borderBottomRightRadius: '30px' }}>
            Level 3
        </a>
    </li>
    <li className="surface-card z-3">
        <a className="cursor-pointer bg-cyan-500 flex align-items-center justify-content-center px-5 py-3 text-white white-space-nowrap shadow-2 h-full" style={{ borderTopRightRadius: '30px', borderBottomRightRadius: '30px' }}>
            Level 2
        </a>
    </li>
    <li>
        <a className="cursor-pointer text-cyan-700 flex align-items-center justify-content-center px-5 py-3 surface-card white-space-nowrap h-full" style={{ borderTopRightRadius: '30px', borderBottomRightRadius: '30px' }}>
            Level 1
        </a>
    </li>
</ul>*/}
    <div className="surface-section px-2 py-4 md:px-2 lg:px-2 flex flex-wrap justify-content-between">
    
    {localChannels && localChannels.map((element:IChannel,index:number) => (
        
        <div className={`lg:col-6 sm:col-12 ${mb2}`}  key={index}>
            <div className="flex flex-wrap align-items-start border-bottom-2 border-green-100 p-2 shadow-1">
                <div className="relative w-full xl:w-6 pr-0 xl:pr-4 mb-4 xl:mb-0" style={{ maxHeight: '200px', overflow: 'hidden' ,textAlign:"center"}}>
                    <img src={`${process.env.REACT_APP_URL_BACKEND}/img/get/channels/${element.id}/fiat-500-4322521_1280.jpg`} alt="Image" style={{width:"310px"}}  />
                    <div className='absolute top-0 right-0 font-bold'>
                    {parseInt(element.isUserLoguedConnected as any) === 1 && <Button className="bg-orange-400 text-black text-sm block p-1 border-round mr-1 mt-1 font-medium border-red-800" onClick={(e) => handleClickChannelDisconnect(element)}><FontAwesomeIcon icon={faRectangleXmark} /> {translations.channels_disconnect}</Button>}
                    </div>
                </div>
                <div className="w-full xl:w-6 pl-2">
                    <div className="flex flex-wrap align-items-center">
                        <img src={`${process.env.REACT_APP_URL_BACKEND}/img/get/user_profiles/${element.id}/avatar-f-3.png`} className="mr-3 flex-shrink-0" width="28" height="28" />
                        <span className="text-900 font-medium text-md white-space-nowrap">{element.author?.username}</span>
                        <span className="text-500 font-medium text-lg mx-2">|</span>
                        <span className="text-500 font-small text-md white-space-nowrap">{formatDate(element.dateCreated)}</span>
                        <div style={{ marginLeft: 'auto',marginRight:"-12px" }}>
                            {element.isUserLoguedConnected != 1 && <Button className="bg-blue-800 text-white text-sm block p-1 border-round mr-3 font-medium border-blue-900" onClick={(e) => handleClickChannel(element)}><FontAwesomeIcon icon={faLink} /> {translations.channels_connect}</Button>}
                            {parseInt(element.isUserLoguedConnected as any) === 1 && <Button className="bg-blue-400 text-white text-sm block p-1 border-round mr-3 font-medium border-blue-900" onClick={(e) => handleClickChannel(element)}><FontAwesomeIcon icon={faLink} /> {translations.channels_enter}</Button>}
                    
                        </div>
                    </div>
                    <div className="text-900 text-base font-bold mt-1">{element.title}</div>
                    <p className="line-height-3 text-700 my-2">{element.description}</p>
                    <div className="flex align-items-center">
                        <span className="text-800 text-sm font-medium">Último mensaje:&nbsp;</span> <span className="text-600 text-sm font-medium"> {formatTimeDifference(element.lastMessageDate)}</span>
                    </div>
                    <div className="flex align-items-center">
                        <span className="text-800 text-sm font-medium">Gente online:&nbsp;</span> <span className="text-600 text-sm font-medium"> {element.usersOnlineInChannel}</span>
                    </div>
                    <div className="flex text-left mt-2 flex-wrap ">
                        <span className="text-600 font-medium text-sm py-1">TAGS: </span>
                        <span className="text-600 font-medium text-sm mx-2">|</span>
                        <ChipTag tags={element.tags}/>
                        {/*<div className='px-1'><Chip label="Nature" className='bg-blue-100'/></div>
                        <div className='px-1'><Chip label="Medio ambiente" className='bg-green-100'/></div>
    <div className='px-1'><Chip label="Clima" className='bg-yellow-100'/></div>*/}
                    </div>
                    <div className="flex text-left mt-2">
                    <span className="bg-yellow-100 text-yellow-600 text-sm block py-1 px-2 border-round mr-3 font-medium">Publico</span>
                    </div>
                    
                </div>
            </div>
        </div>
        
    ))}
        
    </div>
    
    
    </>
}

export default ListChannels;