import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css'
import { AuthProvider } from './componentes/authProvider/AuthContext';
import LanguageProvider from './componentes/providers/LanguageProvider';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
/*<React.StrictMode>*/
root.render(
  
    <AuthProvider>
      <LanguageProvider defaultLanguaje="es">
        <App />
      </LanguageProvider>
    
    </AuthProvider>
    
);
/*</React.StrictMode>*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
