import React, { useState, useRef, useContext } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Slider, SliderChangeEvent } from "primereact/slider";
import ReactCrop, {

  Crop,
  PixelCrop,
} from 'react-image-crop'
import { canvasPreview, centerAspectCrop, doOCR } from './CanvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import Tesseract from 'tesseract.js'
import 'react-image-crop/dist/ReactCrop.css'
import { handleCropImageSave, sendFile } from './ImageCropHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import { DeviceContext } from '../providers/DeviceProvider';
import { FileUpload } from 'primereact/fileupload';
import AuthContext from '../authProvider/AuthContext';
interface ImageCropProps {
    visible:boolean;
    setVisible:(a:boolean) => void
}


  const ImageCropImpl: React.FC<ImageCropProps> = ({visible,setVisible}) => {
   const asp = 6/8
  const [imgSrc, setImgSrc]                     = useState('')
  const previewCanvasRef                        = useRef<HTMLCanvasElement>(null)
  const imgRef                                  = useRef<HTMLImageElement>(null)
  const [crop, setCrop]                         = useState<Crop | undefined>()
  const [completedCrop, setCompletedCrop]       = useState<PixelCrop>()
  const [scale, setScale]                       = useState(1)
  const [showPreview,setShowPreview]            = useState<boolean>(false);
  const [rotate, setRotate]                     = useState(0)
  const [aspect, setAspect]                     = useState<number | undefined>(asp)
  const {isMobile}                              = useContext(DeviceContext);
  const [anyUpload,setAnyUpload]                = useState<boolean>(true);
  const { reloadUserLogued }                    = useContext(AuthContext);


  function onSelectFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.


        const reader = new FileReader()
        reader.addEventListener('load', () => {
          setImgSrc(reader.result?.toString() || '')
          if (reader.result) doOCR(reader.result as string)
        })
        reader.readAsDataURL(e.target.files[0])
      
    }
  }
  const invoiceUploadHandler = ({ files }: any) => {
    const [file] = files;
    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      if (e.target?.result) {
        setImgSrc(fileReader.result?.toString() || '');
        setAnyUpload(false);
        const image = new Image();
        image.src = e.target.result as string;
  
        image.onload = () => {
          
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          if (ctx) {
            canvas.width = image.width;
            canvas.height = image.height;
  
            // Dibujar la imagen en el canvas
            ctx.drawImage(image, 0, 0);
  
            // Calcular el factor de compresión necesario para alcanzar el tamaño deseado
            const desiredFileSizeInBytes = 800 * 1024; // 800 KB en bytes
            let quality = 0.9; // Calidad de compresión inicial
            let compressedDataUrl = canvas.toDataURL('image/jpeg', quality);
            let currentFileSizeInBytes = compressedDataUrl.length * 0.75; // Aproximación del tamaño en bytes
  
            while (currentFileSizeInBytes > desiredFileSizeInBytes && quality > 0.1) {
              quality -= 0.1;
              compressedDataUrl = canvas.toDataURL('image/jpeg', quality);
              currentFileSizeInBytes = compressedDataUrl.length * 0.75;
            }
  
            // Convertir la URL de datos a un blob
            const base64String = compressedDataUrl.split(',')[1];
            const binaryString = window.atob(base64String);
            const length = binaryString.length;
            const bytes = new Uint8Array(length);
            for (let i = 0; i < length; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes], { type: 'image/jpeg' });

          }
        };
      }
    };
    fileReader.readAsDataURL(file);
  };
  

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

 
/*
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
        doOCR(previewCanvasRef.current.toDataURL())
      }
    },
    100,
    [completedCrop, scale, rotate],
  )*/

  const renderPreview = () => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      // We use canvasPreview as it's much faster than imgPreview.
      canvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        completedCrop,
        scale,
        rotate,
      )
      doOCR(previewCanvasRef.current.toDataURL())
    }
  }
  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else if (imgRef.current) {
      const { width, height } = imgRef.current
      setAspect({
        aspect: asp
      } as any)
      setCrop(centerAspectCrop(width, height,asp))
    }
  }

  const handleSave = () => {
    renderPreview()
    handleCropImageSave(previewCanvasRef,async () => {
      reloadUserLogued();
    })
    setVisible(false);
  }
  
const handleShow = () => {
  /*if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    // Verificar si ya se tienen los permisos para acceder a la cámara
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(function (stream) {
        (document.getElementById('btn_upload_img_thumbnail') as any).src = "";
        document.getElementById('btn_upload_img_thumbnail')?.click();
        setImgSrc('');
        setShowPreview(false);
      })
      .catch(function (error) {
        if (error.name === 'NotAllowedError') {
          // El usuario ha denegado el permiso para acceder a la cámara
          mostrarMsg('El usuario ha denegado el permiso para acceder a la cámara');
        } else if (error.name === 'NotFoundError') {
          // No se encontró una cámara en el dispositivo
          mostrarMsg('No se encontró una cámara en el dispositivo');
        } else {
          // Otro tipo de error
          mostrarMsg('Error al acceder a la cámara:');
          alert (JSON.stringify(error))
        }
      });
  } else {
    // El navegador no es compatible con la API getUserMedia
    mostrarMsg('El navegador no admite la API getUserMedia');
  }*/
  setAnyUpload(true);
  setImgSrc('');
  setShowPreview(false);
}
const footerContent = (
    <div>
        <Button label="Cancelar"  severity="secondary" icon="pi pi-times" onClick={() => setVisible(false)} />
        <Button label="Confirmar" icon="pi pi-save" onClick={handleSave} autoFocus />
    </div>
);
  return (
    <Dialog header="Elegí tu foto de perfil" visible={visible} position='top' style={{ width: '70vw' ,height:"100%"}} breakpoints={{ '960px': '75vw', '641px': '100vw' }} maximizable  onShow={handleShow} onHide={() => setVisible(false)} footer={footerContent}>
        <div className=" ">
          <div className="flex justify-content-center flex-wrap card-container yellow-container">
              {anyUpload && <FileUpload name="invoice"
                        accept="image/*"
                        customUpload={true}
                        uploadHandler={invoiceUploadHandler}
                        mode="basic"
                        auto={true}
                        className='flex align-items-center justify-content-center'
                        chooseLabel="Tomar foto">
                </FileUpload>}
          </div>
                

            
            {!!imgSrc && !showPreview &&
                <div className="flex justify-content-center">
                  <div className="flex-justify-content-center" style={{height:"50px"}}>
                    <label htmlFor="integeronly" className="block mb-2 text-center">Zoom</label>
                    
                    <Slider name="zomm_slider" value={scale} min={1} max={5} step={0.1} onChange={(e: SliderChangeEvent) => setScale(e.value as any)} className="w-14rem" />
                    </div>
                </div>
            }
            {!!imgSrc && !showPreview && (<>
                <div className="card flex justify-content-center p-0" style={{padding:'0px !important'}}>
                  <div style={{ width: (isMobile ? '99vw' : '50vw'), height: '300px'}}>
                    <ReactCrop
                        style={{ width: '100%', height: 'auto' }}
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect}
                    >
                      <img
                      ref={imgRef}
                      alt="Crop me"
                      src={imgSrc}
                      style={{
                          transform: `scale(${scale}) rotate(${rotate}deg)`,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                      }}
                      onLoad={onImageLoad}
                      />
                    </ReactCrop>
                    <div className="card flex justify-content-center relative ml-auto mr-auto ">
                      <div className='flex align-items-center justify-content-center mb-7 absolute ml-auto mr-auto ' style={{top:-200}}>
                        <Button className='w-full p-0 pl-1 pr-1'  size="small" icon={<FontAwesomeIcon icon={faCamera} />} onClick={() => {setShowPreview(true);renderPreview()}} label="Previsualizar"></Button>
                      </div>
                    </div>
                  </div>
                </div>
                
                </>
            )}
            <div style={{display: showPreview ? '' : 'none'}}>
                {!!completedCrop && (
                  <div className="card flex justify-content-center">
                  <div className='flex align-items-center justify-content-center mb-7'>
                    <div style={{ width: (isMobile ? '99vw' : '50vw'), height: '300px',marginTop:"-20px" }}>
                    {showPreview && 
                        <div className="flex justify-content-center relative ml-auto mr-auto">
                          <div className='flex align-items-center justify-content-center  absolute ml-auto mr-auto top-0' >
                          <Button className='w-full' icon={<FontAwesomeIcon icon={faEdit} />} onClick={() => setShowPreview(false)} >&nbsp;Seguir modificando</Button>
                        </div>
                        </div>
                      }
                      <div className="card flex justify-content-center">
                        <div className='flex align-items-center justify-content-center mb-7'>
                          <div style={{display:showPreview ? '' : 'none'}}>
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                                maxWidth: (isMobile ? '99vw' : '60vw'),
                                maxHeight: (isMobile ? '500px' : '40vw'),
                                }}
                            />
                          </div>
                        </div>
                      </div>
                      </div>
                      </div>
                    </div>)}
            </div>
            
          </div>
    </Dialog>
    
    
  )
  };
  
  export default ImageCropImpl;
  