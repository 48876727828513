import { classNames } from 'primereact/utils';
import { Ripple } from 'primereact/ripple';


    

interface Props {
    activeTab:number;
    setActiveTab: any;
    className:string;
    title:string;
    tabKey:number
}


const TabItemSetting : React.FC<Props> = ({activeTab,setActiveTab,className,title,tabKey} : Props) => {
    return <>
        <a
            className={classNames(
                "lg:w-15rem flex align-items-center cursor-pointer p-3 border-round hover:surface-200 transition-duration-150 transition-colors",
                {
                    "surface-200":
                        activeTab === tabKey,
                }
            )}
            onClick={(e) => setActiveTab(tabKey)}
        >
            <i
                className={classNames(
                    "pi " + className + " md:mr-2",
                    {
                        "text-700":
                            activeTab === tabKey,
                        "text-600":
                            activeTab !== tabKey,
                    }
                )}
            ></i>
            <span
                className={classNames(
                    "font-medium hidden md:block",
                    {
                        "text-800":
                            activeTab === tabKey,
                        "text-700":
                            activeTab !== tabKey,
                    }
                )}
            >
                {title}
            </span>
            <Ripple />
        </a>
    </>
}

export default TabItemSetting;