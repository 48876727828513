import React, { useRef, useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { OverlayPanel } from 'primereact/overlaypanel';

interface Props {
    handleScrollBottomClick:() => void
    bottomHeight:string
 }


const ScrollButton: React.FC<Props> = ({handleScrollBottomClick,bottomHeight}) => {
    return <><button onClick={handleScrollBottomClick}  type="button" id="btn_go_to_bottom" className="p-scrolltop p-link p-component p-scrolltop-enter-done" 
    style={{zIndex: "7714",width:"30px",height:"30px",position: 'fixed', bottom: `${bottomHeight}`, right: '10px', transform: 'translateX(-50%)' }}>
      <i className='pi pi-angle-down' style={{color: "gray"}}></i> 
      <span role="presentation" className="p-ink" style={{height: "10px",width: "10px"}}></span>
    </button></>
}

export default ScrollButton;