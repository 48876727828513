export async function handleCropImageSave(previewCanvasRef : any,cb : Function) {
    if (previewCanvasRef.current) {
      const canvas = previewCanvasRef.current;

      canvas.toBlob((blob:any) => {
        if (blob) {
          const maxSizeInBytes = 800 * 1024; // 800 KB en bytes
      
          if (blob.size > maxSizeInBytes) {
            // Reducir la calidad de la imagen
            const quality = 0.4; // Calidad de compresión deseada
            const compressedDataUrl = canvas.toDataURL('image/jpeg', quality);


            // Convertir la URL de datos a formato binario
            const base64String = compressedDataUrl.split(',')[1];
            const binaryString = window.atob(base64String);

            // Crear un ArrayBuffer a partir de la cadena binaria
            const length = binaryString.length;
            const bytes = new Uint8Array(length);
            for (let i = 0; i < length; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }

            console.log("pesada reducida")
            const blob = new Blob([bytes], { type: 'image/jpeg' });
            sendFile(blob).then (() => {
              cb();
            });
          } else {
            console.log("sin reducir")
            sendFile(blob).then (() => {
              cb();
            });
          }
        }
      });

      

      //
    }
  }

  
  export const sendFile = async (blob:any) => {
    if (blob) {
      try {
        const formData = new FormData();
        formData.append('image', blob, 'image.png');
  
        const response = await fetch(process.env.REACT_APP_URL_BACKEND + '/api/saveImage/user_profiles', {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          },
          method: 'POST',
          body: formData,
        });
  
        if (response.ok) {
          console.log('Imagen guardada en el servidor');
        } else {
          console.error('Error al guardar la imagen en el servidor');
        }
      } catch (error) {
        console.error('Error de red:', error);
      }
    }
  }