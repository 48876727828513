
import React, { RefObject, createRef, useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import IUser from '@/../../cgo_commons/types/users/IUser';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import SignupLanding from '../../componentes/login/SignupLanding';
import userAxiosInstance from '../../componentes/axios/UserAxios';
import AuthContext from '../../componentes/authProvider/AuthContext';
import { LanguageContext } from '../../componentes/providers/LanguageProvider';
import GoogleLogin from '../../componentes/google/GoogleLogin';
import FacebookLogin from '../../componentes/facebook/FacebookLogin';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

interface Props {
   // setIsAuthenticated : (a: boolean) => void
}
interface LocalIUser extends IUser {
    repass:string
}

export const SignUp = ({}:Props) => {
    const { reset,trigger,control,register, handleSubmit, watch,setValue, formState: { errors } } = useForm<LocalIUser>();
    const [countries, setCountries]                         = useState([]);
    const [checked2, setChecked2]                           = useState(false);
    const { setTokenStorage}                                = useContext(AuthContext);
    const refRePass  : RefObject<HTMLInputElement>          = createRef<HTMLInputElement>();
    const [errMsg,setErrMsg]                                = useState<string>('');
    const emailValue                                        = watch('email');
    const { language, translations }                        = useContext(LanguageContext);
    const navigate                                          = useNavigate();
    //const [formData, setFormData] = useState<LocalIUser>({} as LocalIUser);

    const handleGoogleSuccess = async (token:string) => {
        const restoken = await userAxiosInstance.loginWithGoogle(token);
        //setLoggedIn(true);
        //console.log(userInfo)
        setTokenStorage(restoken);
        navigate('/');
        
    };
    const handleFacebookSuccess = async (token: string,userID:string) => {
        console.log(token)
            const restoken = await userAxiosInstance.loginWithFacebook(token);
        
            setTokenStorage(restoken);
            navigate('/');
          };

      const handleGoogleFailure = (error: Error) => {
        console.log('Error:', error);
      };
      const handleFacebookFailure = (error: Error) => {
        console.log('Error:', error);
      }
    const defaultValues = {
        name: '',
        email: '',
        password: '',
        date: null,
        country: null,
        accept: false
    }

    useEffect(() => {
        document.getElementById('birthDate')?.setAttribute('autocomplete', 'nope');
        //countryservice.getCountries().then(data => setCountries(data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setErrMsg('')
    },[emailValue])
    const onSubmit = (data:LocalIUser) => {
        data.accept = checked2 ? 1 : 0;
        setErrMsg('')
        console.log(data)
        const userAxios = userAxiosInstance.createUser(data).then((r) => {
            console.log(r)
            setTokenStorage(r.token)
        }).catch((e) => {
            setErrMsg(e)
            console.error(e)
        });

        //reset();
    };

    const loadDummy = () => {
        reset({birthDate:new Date()});
    }
    const getFormErrorMessage = (name:string) => {
        return (errors as any)[name] && <small className="p-error">{(errors as any)[name].message}</small>
    };

    
    const passValidate = (iText :string) => {

        console.log(watch('password') + " - " + refRePass.current?.value);
          if (watch('password') !== refRePass.current?.value && refRePass.current?.value !== '') {
            return "Las contraseñas deben coincidir";
          }
        
          return true;
          // return iText[0].toUpperCase() === iText[0];
    }
    const terminosValidate = (value :any)  => {
       
        return (checked2 ? true : "Debes aceptar los términos y condiciones para ingresar al sitio");
        
    }
    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_CLOUD_IDENTITY_API ? process.env.REACT_APP_CLOUD_IDENTITY_API : ''}>
<div style={{ background: 'url("assets/images/blocks/signin/signin-2.jpg") no-repeat', backgroundSize: 'cover' }} className="px-4 py-8 md:px-6 lg:px-8">
    <div className="flex flex-wrap">
        <SignupLanding/>
        <div className="w-full lg:w-6 p-4 lg:p-7 surface-card">
            <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // prevenir comportamiento por defecto
        handleSubmit(onSubmit)(e); // pasar evento a handleSubmit
      }} autoComplete="new-password" >
                <div className="text-900 text-2xl font-medium mb-6">Crea tu perfil</div>

                <label htmlFor="username" className="block text-900 font-medium mb-2">¿Cuál es tu nombre?</label>
                <InputText  id="username" type="text"
                    placeholder='¿Cuál es tu nombre?'
                    autoComplete="new-password"
                    {...register("username", { required:"Nombre requerido"})} 
                    className={classNames('w-full ',errors.username ? "p-invalid" : "") } 
                />
                {getFormErrorMessage('username')}

                <label htmlFor="email" className="block text-900 font-medium mb-2 mt-3">{translations.profile_email}</label>
                <InputText  id="email" type="text"
                    placeholder={translations.profile_email}
                    {...register("email", { required:translations.profile_email_required, pattern: { value:/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,message:translations.profile_email_bad_input } })} 
                    className={classNames('w-full ',errors.email ? "p-invalid" : "") } 
                />
                {getFormErrorMessage('email')}

                <label htmlFor="password" className="block text-900 font-medium mb-2 mt-3">Contraseña</label>
                <InputText id="password" type="password" placeholder="Contraseña" className={classNames('w-full',{ 'p-invalid': errors.password })} autoComplete="new-password"
                    {...register("password", { required: "Contraseña requerida", minLength: {value:1,message:"Mínimo 6 letras"} })} 
                    />
                {getFormErrorMessage('password')}
                <label htmlFor="repass" className="block text-900 font-medium mb-2 mt-3">Repetir Contraseña</label>
                <Controller
                            name="repass"
                            control={control}
                            rules={{ required: "Campo requerido",validate: { value:passValidate } }}

                            render={({ field, fieldState }) => (
                                <InputText  
                                value={field.value ?? ''}
                                ref={refRePass}  
                                autoComplete="new-password" 
                                placeholder='Repetir Contraseña'
                                name="repass"
                                type="password" 
                                onChange={(e) => field.onChange(e.target.value)}
                                className={classNames('w-full',{ 'p-invalid': fieldState.error })}  />
                    )}
                />
                {getFormErrorMessage('repass')}


                <div className="flex align-items-center justify-content-between mb-6 mt-3">
                    <div className="grid">
                        <div className="flex align-items-center col-12">
                            <Checkbox {...register("accept", {validate: {value:terminosValidate} })} 
                                        value="1" id="accept" className="mr-2" checked={checked2} onChange={(e) => {setChecked2(e.checked ? true : false);setTimeout(function () {trigger('accept')},50)}} />
                            <label style={{cursor:"pointer"}} htmlFor="accept" onClick={(e) => {setChecked2(checked2 ? false : true);setTimeout(function () {trigger('accept')},50)}}>He leído y acepto los terminos y condiciones</label>
                            
                        </div>
                    
                        <div className="flex align-items-center justify-content-between col-12 m-0 pb-0 pt-0">
                            {getFormErrorMessage('accept')}
                        </div>
                    </div>
                </div>
                
                
                {errMsg && <div className="text-red-500 w-full surface-overlay mb-2 flex align-items-center justify-content-center">{errMsg}</div>}
                <Button label="Crear mi cuenta" icon="pi pi-user" className="w-full" />

                <Divider align="center" className="my-4">
                    <span className="text-600 font-normal text-sm">OR</span>
                </Divider>

                <GoogleLogin onSuccess={handleGoogleSuccess} typeButton={1} onFailure={handleGoogleFailure}/>
                <FacebookLogin onSuccess={handleFacebookSuccess} typeButton={1}  onFailure={handleFacebookFailure} style={{ marginTop:"20px" }}  />
                {/*<div className="mt-6 text-center text-600">
                    Don't have an account? <a tabIndex={0} className="font-medium text-blue-500" onClick={loadDummy}>Sign up</a>
                </div>*/}
            </form>
            
        </div>
    </div>
</div>
    </GoogleOAuthProvider>
    
    );
}
             