import IUser from "@/../../cgo_commons/types/users/IUser";
import { LanguageContext } from "../../../componentes/providers/LanguageProvider";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import {useContext, useState,useEffect,RefObject,createRef} from 'react';
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { getFormErrorMessage } from "../../../utils/Utils";
import { Calendar } from "primereact/calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import userAxiosInstance from "../../../componentes/axios/UserAxios";
import AuthContext from "../../../componentes/authProvider/AuthContext";
import UpdateFields from "@/../../cgo_commons/types/genericData/UpdateFields";
    

interface Props {
  
}

interface LocalIUser extends IUser {
    repass:string
}
const TabAccount : React.FC<Props> = ({}) => {
    const [checked, setChecked]                             = useState(false);
    const { userLogued,reloadUserLogued,showToast }         = useContext(AuthContext);
    const { language, translations }                        = useContext(LanguageContext);
    const [localUserLogued,setLocalUserLogued]              = useState<IUser>();
    const [allowChgEmail,setAllowChgEmail]                  = useState<boolean>(false);
    const { reset,trigger,control,register, handleSubmit, watch,setValue, formState: { errors } } = useForm<LocalIUser>();
    const refRePass  : RefObject<HTMLInputElement>          = createRef<HTMLInputElement>();
    
    const onSubmit = (data:LocalIUser) => {
        console.log(data)

        const user              = data as IUser & UpdateFields & any;
        user.updateFields="birthDate,phone";
        if (checked) {
            user.updateFields += ",password";
        }
        if (allowChgEmail) {
            user.updateFields +=",email";
        }
        userAxiosInstance.update(user).then((r) => {
            reloadUserLogued();
            showToast({detail:translations.data_saved})
        }).catch((e) => {
            showToast({detail:e.toString(),severity:"error"})
        });
        setChecked(false)
        //reset();
    };

    const loadUserComplete = async () => {
        const user : IUser = (await userAxiosInstance.fetch({id:userLogued?.id} as any))[0];
        setLocalUserLogued(user);
        reset(user as IUser)
        setValue("birthDate",new Date(user?.birthDate as any));
        console.log(user.idFacebook || user.idGoogle ? false : true,user.idFacebook,user.idGoogle)
        setAllowChgEmail(user.idFacebook || user.idGoogle ? false : true);
    }
    useEffect(() => {
        loadUserComplete();
        
        
    },[])
    const passValidate = (iText :string) => {

        console.log(watch('password') + " - " + refRePass.current?.value);
          if (watch('password') !== refRePass.current?.value && refRePass.current?.value !== '') {
            return translations.profile_same_pass;
          }
        
          return true;
          // return iText[0].toUpperCase() === iText[0];
    }
    return <>
        <div className=" surface-section surface-card p-5 pt-1 shadow-2 border-round flex-auto xl:ml-2">
            <div className="text-900 font-semibold text-lg mt-3">
                {translations.my_account} (Información Privada)
            </div>
            <Divider></Divider>
            <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                                    e.preventDefault(); // prevenir comportamiento por defecto
                                    handleSubmit(onSubmit)(e); // pasar evento a handleSubmit
                            }} autoComplete="new-password" >
                <div className="flex gap-5 flex-column-reverse md:flex-row">
                    <div className="flex-auto p-fluid">
                        <div className="mb-3">
                            <label
                                htmlFor="email"
                                className="block font-normal text-900 mb-2"
                            >
                                {translations.profile_email}
                            </label>
                            <InputText  id="email" type="text"
                            disabled={!allowChgEmail}
                                placeholder={translations.profile_email}
                                {...register("email", { required:translations.profile_email_required, pattern: { value:/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,message:translations.profile_email_bad_input } })} 
                                className={classNames('w-full ',errors.email ? "p-invalid" : "") } 
                            />
                            {getFormErrorMessage(errors,'email')}
                            {/*<span className="text-500 text-xs" style={{display: "block" }}>{translations.profile_email_google_face_associate}</span>*/}
                            <span className="text-500 text-xs text-blue-500" style={{display: "block" }}>{translations.profile_not_show_email}</span>
                        </div>
                        <div className="mb-3">
                            <label
                                htmlFor="phone"
                                className="block font-normal text-900 mb-2"
                            >
                                {translations.profile_phone}
                            </label>
                            <InputText  id="phone" type="text"
                                placeholder={translations.profile_phone}
                                {...register("phone", { required:true} ) }
                                className={classNames('w-full ',errors.phone ? "p-invalid" : "") } 
                            />
                            <span className="text-500 text-xs">{translations.profile_txt_wts}</span>
                            <span className="text-500 text-xs text-blue-500" style={{display: "block" }}>{translations.profile_not_show_phone}</span>
                            {getFormErrorMessage(errors,'phone')}
                        </div>
                        <div className="mb-3 lg:col-4 p-0">
                            <label
                                htmlFor="birthDate"
                                className="block font-normal text-900 mb-2"
                            >
                                {translations.profile_birthdate}
                            </label>
                            <Controller
                                name="birthDate"
                                control={control}
                                rules={{ required: true }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <Calendar 
                                        inputId={field.name} 
                                        value={field.value} 
                                        placeholder={translations.profile_birthdate}
                                        onChange={field.onChange} 
                                        dateFormat="dd/mm/yy"
                                        locale="es"
                                        className={classNames('w-full',{ 'p-invalid': fieldState.error })} />
                                        
                                    </>
                                )}
                            />
                            <span className="text-500 text-xs text-blue-500" style={{display: "block" }}>{translations.profile_txt_birthdate}</span>
                            {getFormErrorMessage(errors,'birthDate')}
                        </div>
                        {allowChgEmail &&
                            <div className="flex align-items-center mb-3">
                                <Checkbox
                                    inputId="chgPass"
                                    onChange={(e) =>
                                        setChecked(
                                            e.checked as any
                                        )
                                    }
                                    checked={checked}
                                ></Checkbox>
                                <span className="ml-2 font-normal text-base text-color-primary cursor-pointer" onClick={() => setChecked(!checked as any)}>
                                    {translations.profile_change_pass}
                                </span>
                            </div>
                        }
                        {checked &&
                                <>
                                <div className="mb-3">
                                    <label htmlFor="password" className="block font-normal text-900 mb-2">{translations.profile_password}</label>
                                    <InputText id="password" type="password" placeholder={translations.profile_password} className={classNames('w-full',{ 'p-invalid': errors.password })} autoComplete="new-password"
                                        {...register("password", { required: translations.profile_password_required, minLength: {value:1,message:translations.profile_min_six_letters} })} 
                                        />
                                    {getFormErrorMessage(errors,'password')}
                                </div>
                                
                                <div className="mb-3">
                                    <label htmlFor="repass" className="block font-normal text-900 mb-2">{translations.profile_repassword}</label>
                                    <Controller
                                                name="repass"
                                                control={control}
                                                rules={{ required: translations.required,validate: { value:passValidate } }}

                                                render={({ field, fieldState }) => (
                                                    <InputText  
                                                    value={field.value ?? ''}
                                                    ref={refRePass}  
                                                    autoComplete="new-password" 
                                                    placeholder={translations.profile_repassword}
                                                    name="repass"
                                                    type="password" 
                                                    onChange={(e) => field.onChange(e.target.value)}
                                                    className={classNames('w-full',{ 'p-invalid': fieldState.error })}  />
                                        )}
                                    />
                                    {getFormErrorMessage(errors,'repass')}
                                </div>
                                </>
                        }
                        <div>
                            <Button
                            icon={<FontAwesomeIcon icon={faSave}/>}
                                className="w-auto mt-3"
                            >
                                &nbsp;{translations.profile_btn_update}
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </>
}

export default TabAccount;