import {useContext, useState,useEffect} from 'react';
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { LanguageContext } from '../../componentes/providers/LanguageProvider';
import { DeviceContext } from '../../componentes/providers/DeviceProvider';
import { useForm,Controller } from 'react-hook-form';
import IUser from '../../../../cgo_commons/types/users/IUser';
import { classNames } from 'primereact/utils';
import TabItemSetting from './inc/TabItemSetting';
import TabAccount from './inc/TabAccount';
import TabProfile from './inc/TabProfile';



    

interface Props {
    //setIsAuthenticated : (a: boolean) => void
}


const Setting : React.FC<Props> = ({}) => {
    const [activeTab, setActiveTab]                         = useState(0);
    const [checked, setChecked]                             = useState(false);
    const { reset,trigger,control,register, handleSubmit, watch,setValue, formState: { errors } }   = useForm<IUser>();
    const { language, translations }                        = useContext(LanguageContext);
    const {isMobile}                                        = useContext(DeviceContext);
    const [city, setCity]                                   = useState<string | null>();
    const [country1, setCountry1] = useState(null);
    const [showPermissionMessage, setShowPermissionMessage] = useState(false);

    const countries = [
        { name: "Australia", code: "AU" },
        { name: "Brazil", code: "BR" },
        { name: "China", code: "CN" },
        { name: "Egypt", code: "EG" },
        { name: "France", code: "FR" },
        { name: "Germany", code: "DE" },
        { name: "India", code: "IN" },
        { name: "Japan", code: "JP" },
        { name: "Spain", code: "ES" },
        { name: "United States", code: "US" },
    ];

   

    return <>
          
          <div className="flex flex-column flex-auto ">
                <div className="surface-section px-1 py-1 lg:py-3 lg:px-3 h-full border-round-top-3xl">
                    <div className="flex flex-column md:flex-row w-full justify-content-between md:align-items-center">
                        <div>
                            <h2 className="mt-0 mb-2 text-900 font-medium text-2xl">
                                {translations.title_profile}
                            </h2>
                            <p className="mt-0 mb-0 text-500">
                                {translations.subtitle_profile}
                            </p>
                        </div>

                    </div>
                    <Divider className="my-5"></Divider>
                    <div className="p-fluid flex flex-column lg:flex-row">
                        <ul className="list-none m-0 p-0 flex flex-row lg:flex-column justify-content-between lg:justify-content-start mb-5 lg:mb-0">
                            <li>
                                <TabItemSetting activeTab={activeTab} tabKey={0} setActiveTab={setActiveTab} className='pi-user' title='Mi Cuenta'/>
                            </li>
                            <li>
                                <TabItemSetting activeTab={activeTab} tabKey={1} setActiveTab={setActiveTab} className='pi-cog' title='Mi Perfil'/>
                            </li>
                            <li>
                                <TabItemSetting activeTab={activeTab} tabKey={2} setActiveTab={setActiveTab} className='pi-palette' title='Fotos'/>
                            </li>
                            
                            <li>
                                <TabItemSetting activeTab={activeTab} tabKey={3} setActiveTab={setActiveTab} className='pi-bell' title='Notificaciones'/>
                            </li>
                        </ul>
                        {activeTab === 0 && <TabAccount/>}
                        {activeTab ===1 && <TabProfile/>}
                    </div>
                </div>
            </div>
    
    </>
}

export default Setting;