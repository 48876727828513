import ChatPlaceHolder from '../../componentes/chats/placeHolder/ChatPlaceHolder';
import ChatInputText from '../../componentes/chats/inputTexts/ChatInputText';
import {useEffect, useRef, useState,useLayoutEffect, useContext} from 'react';
import AuthContext from '../../componentes/authProvider/AuthContext';
import IUser from '@/../../cgo_commons/types/users/IUser';
import { useLocation } from "react-router-dom";
import { DeviceContext } from '../../componentes/providers/DeviceProvider';
import ChatContext from '../../componentes/chats/context/ChatContext';
import { debounce } from '../../utils/Utils';
import TypingQueue from '../../utils/TypingQueue';
import IChannel from '@/../../cgo_commons/types/channels/IChannel';
interface Props { }


const GeneralChat: React.FC<Props> = props => {
    const { token,userLogued }                      = useContext(AuthContext);
    const {windowWidth,windowHeight}                = useContext(DeviceContext);
    const {socket,isConnected}                      = useContext(ChatContext);
    const [showScrollBottom, setShowScrollBottom]   = useState<boolean>(false);
    const scrollableDivRef                          = useRef<HTMLDivElement>(null);
    const [maxHeight,setMaxHeight]                  = useState<string>(windowHeight-110 + "px");
    const [bottomHeight,setBottomHeight]            = useState<string>("50px");
    const [channel,setChannel]                      = useState<IChannel | null>(null);
    const typingQueue                               = new TypingQueue(function () {
      console.log("typing")
      socket?.emit('typing', { channel:channel?.id,sender:userLogued });
    });
    const [joined,setJoined]                        = useState(false);
    const {state}                                   = useLocation();
    
    // Manejar eventos de chat
  const handleSendMessagePrivate = (message : string) => {
    //e.preventDefault();
    const msg = {message:message,username:userLogued?.username,recipient:message.split("|")[0]}
    console.log(msg)
    console.log(message)
    if (socket) {
        socket.emit("private_message", msg);
    }
  };
  const handleSendMessage = (message : string) => {
    //e.preventDefault();
    //console.log("handleSendMessage socket:" + socket?.id + " channel:" + channel)
    if (message && isConnected && socket && userLogued) {
      socket.emit("channel_message", {sender:{id:userLogued.id,username:userLogued.username},message:message,channel:channel?.id});
      /*const msg = message.split("|")[1]
      if (message.split("|")[0].startsWith('fn_')) {
        console.log(message.split("|")[0])
        socket.emit(message.split("|")[0], {sender:userLogued?.email,channel:(userLogued?.email === 'pepe' ? 'test' : "main"),message:msg});
      } else {
        socket.emit("channel_message", {sender:userLogued?.email,channel:(userLogued?.email === 'pepe' ? 'test' : "main"),message:msg});

      }*/
      
    } else {
      console.log("los mensajes deben tener un |, si el primer [] empeiza con fn_ se toma como funcion, sino como recipient")
    }
    
  };
  const handleJoin = (userLogued:IUser,channel:IChannel) => {
    //e.preventDefault();
    //if (socket) {
        
        const a = socket as any;
        if (userLogued?.id != null) {

          console.log("join" , state.channel)
          console.log({id:state.channel,type:state.type} )
          a.emit("join", {id:userLogued?.id,channel:state.channel,channelType:state.type});
        }
        
   // }
  };
  useEffect(() => {

    if (userLogued && socket && isConnected && !joined && channel && channel.id>0) {
        handleJoin(userLogued,channel);
        setJoined(true);
    }
},[userLogued,isConnected,socket,joined,channel])
useEffect(() => {
  if (state && state.channel) {
    setChannel({id:state.channel,type:state.type} as any);
  }
},[state])
// Manejar el evento de scroll para mostrar u ocultar el botón de scroll
    const handleScroll = () => {
        if (scrollableDivRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollableDivRef.current;

            setShowScrollBottom(scrollTop + clientHeight < scrollHeight - 20);
        }
        
    };
    /**
     * cuando se recibe un mensaje va al bottom si el usr esta en el bottom
     */
    const handleNewMessageScroll = () => {
      
        
        setTimeout(function () {
          if (scrollableDivRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollableDivRef.current;
            console.log((scrollTop + clientHeight) + " < " + (scrollHeight - 10) + " -- " + ((scrollHeight - 10) - (scrollTop + clientHeight)))
            if (((scrollHeight - 10) - (scrollTop + clientHeight))<15) {
              scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight - scrollableDivRef.current.clientHeight;
            }
          }
        },100)

      
    }
    useEffect(() => {
        // Hacer scroll al fondo del div al montar el componente
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight - scrollableDivRef.current.clientHeight;
        }

        adjustHeight();

        const handleResize = () => {
          adjustHeight();
        }
        // Agregar el evento para ajustar las alturas
        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      useEffect(() => {
        if (!isConnected && joined) {
          setJoined(false);
        }
      },[isConnected]);
      const adjustHeight = (emojiHeight?:number) => {
        const msg = document.getElementById('chat_messages') as HTMLDivElement;//contenbedor interno de los input text y emojis
        const iTxt = document.getElementById('chat_inputText') as HTMLDivElement;//contenedor externo que tiene todos los input
        const iTxtReal = document.getElementById('chat_input_text') as HTMLDivElement;//input div editable
        const chatContainer = document.getElementById('chat_container') as HTMLDivElement;
        const inputContainer = document.getElementById('inputContainer') as HTMLDivElement;//este div tiene el div editable y se agranda segun el div editable
        // Obtener la altura de la ventana y dividirla entre 2
        const halfHeight = chatContainer?.offsetHeight;
        // Función para ajustar la altura de los divs
        const insideAdjustHeight = () => {
          const div1Height = msg.offsetHeight;
          const div2Height = iTxtReal.scrollHeight;
          const diviTxtHeight = inputContainer.offsetHeight;//altura del div que contiene los emojis y el input

          if (emojiHeight && emojiHeight>1) {
            msg.style.maxHeight = `${windowHeight - diviTxtHeight - 80 - emojiHeight}px`;
            msg.style.minHeight = `${windowHeight - diviTxtHeight - 80 - emojiHeight}px`;
            //iTxt.style.height = `${div2Height + emojiHeight}px`;
          } else {
            msg.style.maxHeight = `${windowHeight - diviTxtHeight - 80  }px`;
            msg.style.minHeight = `${windowHeight - diviTxtHeight - 80  }px`;
            //iTxt.style.height = `${div2Height}px`;
          }
          
          setBottomHeight((diviTxtHeight +30) + "px");//seteo la posision bottom del btn que vuelve a abajo de todo del container de texto

        };
        insideAdjustHeight();
      }
    // Actualizar la posición del scroll cuando se agreguen nuevos elementos
    useEffect(() => {
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
        }
        
    }, [scrollableDivRef.current?.childElementCount]);

    useEffect(() => {
      adjustHeight();
    },[windowHeight])
    // Actualizar la posición del scroll cuando el div esté montado
    useLayoutEffect(() => {
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
        }
    }, []);
    const handleScrollBottomClick = () => {
      if (scrollableDivRef && scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
      }
      
  };

  const handleTyping = () => {
    typingQueue.addToTypingQueue()
  };
    return (<>
    
    <div className='container' id="chat_container">

        <div className='full-height ' ref={scrollableDivRef } onScroll={handleScroll}  id="chat_messages">
         <ChatPlaceHolder showScrollBottom={showScrollBottom} handleScrollBottomClick={handleScrollBottomClick} bottomHeight={bottomHeight} handleNewMessageScroll={handleNewMessageScroll}/>
        </div>
        <div className='small-height  align-content-end flex-wrap card-container green-container' id="chat_inputText" >{/*style={{position: "sticky",bottom: "0"}}*/}
            <ChatInputText placeholder='Escribir...' adjustHeight={adjustHeight} handleSendMessage={handleSendMessage} handleTyping={handleTyping}/>
        </div>
    </div>
    </>)
}
export default GeneralChat;