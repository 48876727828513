import IUser from "../../../../cgo_commons/types/users/IUser";
import getAxiosInstance from "../../utils/AxiosUtil";
import BackendResponse from '../../../../cgo_commons/axios/BackendResponse'
import UpdateFields from "@/../../cgo_commons/types/genericData/UpdateFields";
import UserFilter from "@/../../cgo_commons/filters/users/UserFilter";

class UserAxios {

    private axiosInstance : any;
    private statusOk : number;
    constructor() {
        this.axiosInstance = getAxiosInstance();
        this.statusOk = 200;
    }

    /*public async createUser(user : IUser) : Promise<IUser> {
        try {
            const result : BackendResponse | any = await this.getAxios().post("/login/create",user);
        
            if (result.status != this.statusOk) {
                console.log(result.result)
                throw new Error(result.errorResponse?.message);
            }
            return result.data.data;
        } catch (e : any) {
            throw new Error(e.response.data.errorResponse.message);
        }
        
    }*/

    public async loginWithFacebook(token:string) {
        return new Promise<string>((resolve, reject) => {
            this.getAxios()
              .post("/login/facebook", {token:token})
              .then((data: BackendResponse<any> | any) => {
                  if (data.data.data.token) {
                    resolve(data.data.data.token);
                  } else {
                    reject();
                  }
                  

              })
              .catch((e: any) => {
                console.log(e)
                reject(e.response.data.errorResponse.message);
              });
          });
    }

    public async loginWithGoogle(token:string) {
        return new Promise<string>((resolve, reject) => {
            this.getAxios()
              .post("/login/google", {token:token})
              .then((data: BackendResponse<any> | any) => {
                  if (data.data.data.token) {
                    resolve(data.data.data.token);
                  } else {
                    reject();
                  }
                  

              })
              .catch((e: any) => {
                console.log(e)
                reject(e.response.data.errorResponse.message);
              });
          });
    }
    public async createUser(user: IUser): Promise<any> {
        return new Promise<IUser>((resolve, reject) => {
          this.getAxios()
            .post("/login/create", user)
            .then((result: BackendResponse<IUser> | any) => {
              if (result.status !== this.statusOk) {
                console.log(result.result);
                reject(result.errorResponse?.message);
              } else {
                resolve(result.data.data);
              }
            })
            .catch((e: any) => {
              reject(e.response.data.errorResponse.message);
            });
        });
      }
    
    public async update(user: IUser & UpdateFields) : Promise<IUser[]> {
        try {
            const result : BackendResponse<IUser> | any = await getAxiosInstance().put("/api/user" ,user);

            if (result.status != this.statusOk) {
                throw new Error(result.errorResponse?.message);
            }
            return result.data.data;
        } catch (e : any) {
            throw new Error(e.response.data.errorResponse.message);
        }
        
    }
    public async fetch(filter : UserFilter) : Promise<IUser[]> {
        try {
            const result : BackendResponse<IUser[]> | any = await getAxiosInstance().post("/api/user/fetch" ,filter);

            if (result.status != this.statusOk) {
                console.log(result.result)
                throw new Error(result.errorResponse?.message);
            }
            return result.data.data;
        } catch (e : any) {
            throw new Error(e.response.data.errorResponse.message);
        }
        
    }
    public async delete(id : number) : Promise<void> {
        console.log(id)
        try {
            if (id == null || id<1) {
                throw new Error("Id incorrecto");
            }
            await this.getAxios().delete("/api/user/" + id);
        
        } catch (e : any) {
            console.error(e)
            throw new Error(e.response.data.errorResponse.message);
        }
    }

    public async changePassword(user : IUser) : Promise<void> {
        try {
            await this.getAxios().put("/api/user/changePassword",user);
        
            
        } catch (e : any) {
            throw new Error(e.response.data.errorResponse.message);
        }
        
    }
    private getAxios() {
        if (!this.axiosInstance) {
            this.axiosInstance = getAxiosInstance();
        }
        return this.axiosInstance;
    }
}

const userAxiosInstance = new UserAxios();
export default userAxiosInstance;