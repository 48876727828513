import React, { useRef, useState,useEffect } from 'react';


interface Props {
    handleItemClick: any;
    msg:{sender:{id:number,username:string},message:string}
 }


const ChatMessage: React.FC<Props> = ({handleItemClick,msg}) => {
    const parseText = (txt: string) => {
        const lines = txt.split('\n');
        if (lines.length <= 3) {
          return lines.map((line, index) => (
            <div key={index}>{line}</div>
          ));
        } else {
          const truncatedLines = lines.slice(0, 2); // Obtener las primeras 2 líneas
          const remainingLines = lines.slice(2); // Obtener las líneas restantes
          const mergedLines = remainingLines.join(' '); // Concatenar las líneas restantes en un solo string
          const finalText = truncatedLines.concat(mergedLines); // Unir las primeras 2 líneas con el texto concatenado
      
          return finalText.map((line, index) => (
            <div key={index}>{line}</div>
          ));
        }
      };
    return <>
    <div>
        <div className="flex m-1">
          <a onClick={(e) => handleItemClick(msg.sender.username, e)}>
            <b>@{msg.sender.username}</b>
          </a>
          <div className='ml-1'>{parseText(msg.message)}</div>
        </div>
      </div>
              </>
}

export default ChatMessage;