
import React, { RefObject, createRef, useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import IUser from '@/../../cgo_commons/types/users/IUser';
import {genderOptions,sexualPreferencesOptions} from '@../../../../cgo_commons/utilities/SelectOptions'
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import SignupLanding from '../../componentes/login/SignupLanding';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import AuthContext from '../../componentes/authProvider/AuthContext';
import userAxiosInstance from '../../componentes/axios/UserAxios';
import UpdateFields from '@/../../cgo_commons/types/genericData/UpdateFields';
import { Checkbox } from 'primereact/checkbox';
interface Props {

}

addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar',
    dateFormat : 'dd/mm/yy'
    //...
});
export const LoginInfoRequired = ({}:Props) => {
    const { reset,trigger,control,register, handleSubmit, watch,setValue, formState: { errors } }   = useForm<IUser>();
    const { logout,reloadUserLogued,userLogued,isUserInfoLoaded}                                    = useContext(AuthContext);
    const [countries, setCountries]                         = useState([]);
    const [checked2, setChecked2]                           = useState(false);
    const refRePass  : RefObject<HTMLInputElement>          = createRef<HTMLInputElement>();
    const listGenderOptions                                 = genderOptions();
    const listSexualPreferencesOptions                      = sexualPreferencesOptions();
    useEffect(() => {
        
        document.getElementById('birthDate')?.setAttribute('autocomplete', 'nope');
        //countryservice.getCountries().then(data => setCountries(data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const onSubmit = (data:IUser) => {
        const user              = data as IUser & UpdateFields & any;
        user.genero             = user.genero.id;
        user.sexPreferencias    = user.sexPreferencias.id;
        if (userLogued?.accept === 0) {//si accept es 0 es que se registro con google o facebook, por lo que se debe actualizar la aceptacion de T&C
            user.updateFields="birthDate,genero,sexPreferencias,accept";
            user.accept         = checked2 ? 1: 0;
        } else {
            user.updateFields="birthDate,genero,sexPreferencias";
        }
        
        userAxiosInstance.update(user).then((r) => {
            reloadUserLogued();
        }).catch((e) => {
            //setErrMsg(e)
            console.error(e)
        });

        reset();
    };


    const getFormErrorMessage = (name:string) => {
        return (errors as any)[name] && <small className="p-error">{(errors as any)[name].message}</small>
    };

    const terminosValidate = (value :any)  => {
       
        return (checked2 ? true : "Debes aceptar los términos y condiciones para ingresar al sitio");
        
    }
  

    return (
      
<div style={{ background: 'url("assets/images/blocks/signin/signin-2.jpg") no-repeat', backgroundSize: 'cover' }} className="px-4 py-8 md:px-6 lg:px-8">
    <div className="flex flex-wrap">
        <SignupLanding/>
        <div className="w-full lg:w-6 p-4 lg:p-7 surface-card">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="new-password" >

                <div className="text-xl font-medium mb-6">Último paso para finalizar tu registración</div>

                <Controller
                    name="birthDate"
                    control={control}
                    rules={{ required: 'Fecha requerida.' }}
                    render={({ field, fieldState }) => (
                        <>
                            <Calendar 
                            inputId={field.name} 
                            value={field.value} 
                            placeholder='Tú fecha de nacimiento'
                            onChange={field.onChange} 
                            dateFormat="dd/mm/yy"
                            locale="es"
                            className={classNames('w-full mt-3',{ 'p-invalid': fieldState.error })} />
                            
                        </>
                    )}
                />
                {getFormErrorMessage('birthDate')}

                <Controller
                    name="genero"
                    control={control}
                    rules={{ required: 'Género requerido.' }}
                    render={({ field, fieldState }) => (
                            <Dropdown
                                id={field.name}
                                value={field.value}
                                optionLabel="text"
                                placeholder="¿Cuál es tu género?"
                                options={listGenderOptions}
                                focusInputRef={field.ref}
                                onChange={(e) => field.onChange(e.value)}
                                className={classNames('mt-4 w-full',{ 'p-invalid': fieldState.error })}
                            />
                    )}
                />
                {getFormErrorMessage('genero')}

                
                <Controller
                    name="sexPreferencias"
                    control={control}
                    rules={{ required: 'Orientación sexual requerido.' }}
                    render={({ field, fieldState }) => (
                            <Dropdown
                                id={field.name}
                                value={field.value}
                                optionLabel="text"
                                placeholder="¿Cuál es tu orientación sexual?"
                                options={listSexualPreferencesOptions}
                                focusInputRef={field.ref}
                                onChange={(e) => field.onChange(e.value)}
                                className={classNames('mt-4 w-full',{ 'p-invalid': fieldState.error })}
                            />
                    )}
                />
                {getFormErrorMessage('sexPreferencias')}
                {isUserInfoLoaded && userLogued?.accept === 0 && 
                    (
                        <div className="flex align-items-center justify-content-between mb-6 mt-3">
                            <div className="grid">
                                <div className="flex align-items-center col-12">
                                    <Checkbox {...register("accept", {validate: {value:terminosValidate} })} 
                                                value="1" id="accept" className="mr-2" checked={checked2} onChange={(e) => {setChecked2(e.checked ? true : false);setTimeout(function () {trigger('accept')},50)}} />
                                    <label style={{cursor:"pointer"}} htmlFor="accept" onClick={(e) => {setChecked2(checked2 ? false : true);setTimeout(function () {trigger('accept')},50)}}>He leído y acepto los terminos y condiciones</label>
                                    
                                </div>
                            
                                <div className="flex align-items-center justify-content-between col-12 m-0 pb-0 pt-0">
                                    {getFormErrorMessage('accept')}
                                </div>
                            </div>
                        </div>
                    )
                }
                <Button label="Acceder" icon="pi pi-user" className="mt-5 w-full" />
                <Button label="Salir" type="button" icon={<FontAwesomeIcon icon={faRightFromBracket} />} className="mt-4 w-full" severity="secondary" onClick={logout}/>
            </form>
            
        </div>
    </div>
</div>
    
    
    );
}
             