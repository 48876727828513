import React, { useContext, useState,useEffect } from 'react';
import { BrowserRouter as Router,  Route,  BrowserRouter, Routes , Navigate, useNavigate } from 'react-router-dom';
import AuthContext, { AuthProvider } from './componentes/authProvider/AuthContext';

import Blank from './pantallas/Blank';
import Main from './pantallas/Main';
import GeneralChat from './pantallas/chat/GeneralChat';
import { ChatProvider } from './componentes/chats/context/ChatContext';
import ListChannels from './pantallas/chat/ListChannels';
import { SignUp } from './pantallas/login/SignUp';
import { LoginInfoRequired } from './pantallas/login/LoginInfoRequired';
import Setting from './pantallas/account/Setting';


const Login = React.lazy(() => import('./pantallas/login/Login'))
//const UsersList = React.lazy(() => import('./pantallas/users/UsersList'))
function App() {
  //const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { token,isAuthenticated,isUserInfoLoaded } = useContext(AuthContext);
  // Función para validar el token JWT
  const validateToken = () => {
   /* if (isAutenticated) {

      setIsAuthenticated(true);
      
    } else {
      setIsAuthenticated(false);
      //document.location.href = "/"
    }*/
  };
  useEffect(() => {

  },[isAuthenticated])
  // Llamar a la función de validación cuando se monte el componente
  React.useEffect(() => {
    validateToken();
  }, []);
  React.useEffect(() => {
    validateToken();
  }, [token]);
  return (
    <ChatProvider>
      <BrowserRouter>
      <React.Suspense fallback={<div>loading</div>}>
        <Routes>
            {!isAuthenticated && isUserInfoLoaded &&
              <>
                <Route  path={`${process.env.PUBLIC_URL}/*`} element={<LoginInfoRequired  />}></Route>
              </>
            }
            {!isAuthenticated && !isUserInfoLoaded &&
              <>
                <Route  path={`${process.env.PUBLIC_URL}/*`} element={<Login  />}></Route>
                <Route  path={`${process.env.PUBLIC_URL}/signup`} element={<SignUp />}></Route>
              </>
            }
            {isAuthenticated && <>
              <Route path={`${process.env.PUBLIC_URL}/inforequired`} element={
                                                          <div><RequireAuth  isAuthenticated={isAuthenticated} redirectTo="/login">
                                                            <LoginInfoRequired />
                                                          </RequireAuth></div>
                                                          }/>
            </>}
            {isAuthenticated && <>
              <Route path={`${process.env.PUBLIC_URL}/`} element={<Main />}>
                <Route index element={
                                                          <div><RequireAuth isAuthenticated={isAuthenticated} redirectTo="/login">
                                                            <ListChannels />
                                                          </RequireAuth></div>
                                                          }/>
                <Route path={`${process.env.PUBLIC_URL}/chat`} element={
                                                          <div><RequireAuth isAuthenticated={isAuthenticated} redirectTo="/login">
                                                            <GeneralChat />
                                                          </RequireAuth></div>
                                                          }/>
                <Route path={`${process.env.PUBLIC_URL}/channel`} element={
                                                          <div><RequireAuth  isAuthenticated={isAuthenticated} redirectTo="/login">
                                                            <ListChannels />
                                                          </RequireAuth></div>
                                                          }/>
                <Route path={`${process.env.PUBLIC_URL}/setting`} element={
                                                          <div><RequireAuth  isAuthenticated={isAuthenticated} redirectTo="/login">
                                                            <Setting />
                                                          </RequireAuth></div>
                                                          }/>
                <Route path={`${process.env.PUBLIC_URL}/*`} element={
                                                          <div><RequireAuth  isAuthenticated={isAuthenticated} redirectTo="/login">
                                                            <ListChannels />
                                                          </RequireAuth></div>
                                                          }/>
              </Route>
            </>
            }
            
            

        </Routes>
      </React.Suspense>
      </BrowserRouter>
    </ChatProvider>
  );
}

interface RequireAuthProps {
  children: React.ReactNode;
  redirectTo: string;
  isAuthenticated:boolean;
}

const RequireAuth: React.FC<RequireAuthProps> = ({
  children,
  redirectTo,
  isAuthenticated
}) => {
  const a = localStorage.getItem('token');
  if (!a) {
    //setIsAuthenticated(false);
  }
  return isAuthenticated ? (
    <div>{children}</div>
  ) : (
    <Navigate to={redirectTo} />
  );
 /* const a = localStorage.getItem('token');
  setIsAuthenticated(a !== '' && a !== null);
  return a !== '' && a !== null ? (
    <div>{children}</div>
  ) : (
    <Navigate to={redirectTo} />
  );*/
};
export default App;