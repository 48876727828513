import React, { useRef, useState,useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { OverlayPanel } from 'primereact/overlaypanel';
import ScrollButton from './ScrollButton';
import ChatContext from '../context/ChatContext';
import ChatMessage from './ChatMessage';
import IChannel from '@/../../cgo_commons/types/channels/IChannel';

interface Props {
    showScrollBottom: boolean;
    handleScrollBottomClick:() => void;
    bottomHeight:string;
    handleNewMessageScroll: () => void
 }


const ChatPlaceHolder: React.FC<Props> = ({showScrollBottom,handleScrollBottomClick,bottomHeight,handleNewMessageScroll}) => {
    const [selectedItem, setSelectedItem]     = useState<string>('test');
    const overlayPanelRef                     = useRef<OverlayPanel>(null);
    const [mensajes,setMensajes]              = useState<any[]>([]);
    const {handleAddListener,handleRemoveListener,isConnected,channel} = useContext(ChatContext);
    const handleItemClick = (item: string,e:any | null) => {
        setSelectedItem(item);
        console.log(item)
        if (overlayPanelRef.current) {
            console.log(e)
            overlayPanelRef.current.toggle(e);
        }
        
    };
    const handleReceiveMessage = (obj : {sender:{id:number,username:string},message:string,channelId:number}) => {
      if (channel.id === obj.channelId) {
        setMensajes(prevMensajes => [...prevMensajes, obj]);
        handleNewMessageScroll();
      }
      
    }
    useEffect(() => {
      handleAddListener('channel_message',handleReceiveMessage)
      return () => {
        handleRemoveListener('channel_message');
      }
    },[isConnected])

useEffect(() => {
  /*if (showScrollBottom) {
    const objBtn = document.getElementById('btn_go_to_bottom') as HTMLButtonElement;
    console.log(objBtn)
    console.log(bottomHeight)

    if (bottomHeight!=null && bottomHeight!='' && objBtn) {
      objBtn.style.bottom = `${bottomHeight}px`;
    console.log(bottomHeight)
    }
    
  }*/
  console.log(bottomHeight)
},[bottomHeight,showScrollBottom])

    return <>
        <div className='fontMirc'>

             {/*<button onClick={handleScrollBottomClick}>Scroll hacia abajo</button>*/}
            {showScrollBottom && <ScrollButton bottomHeight={bottomHeight} handleScrollBottomClick={handleScrollBottomClick}/>}

            {mensajes.map((msg:{sender:{id:number,username:string},message:string},index:number) => (
              <ChatMessage handleItemClick={handleItemClick} msg={msg}/>
            ))}
            {/*<div>
              instrucciones: fn_user_channel_connected|texto<br></br>
              fn_user_connected|texto

            </div>*/}
{/*<span className="emoji-mart-emoji" data-emoji-set="apple">
  <span
    style={{
      display: "block",
      width: "24px",
      height: "24px",
      backgroundImage:
        'url("https://cdn.jsdelivr.net/npm/emoji-datasource-apple@14.0.0/img/apple/sheets-256/64.png")',
      backgroundSize: "6100% 6100%",
      backgroundPosition: "53.3333% 35%",
    }}
  ></span>
</span>*/}
{/*

ESTO ME DIO OPEN IA PARA GENERAR EL EMOJI POR CADA EMOJI APPLE
const emojiElements = document.querySelectorAll('.emoji-mart-emoji');
emojiElements.forEach((emojiElement) => {
  const emoji = emojiElement.getAttribute('aria-label');
  const emojiImage = emojiElement.querySelector('img');
  const emojiImageUrl = emojiImage.getAttribute('src');
  console.log(`<span className="emoji-mart-emoji" data-emoji-set="apple">
  <span
    style={{
      display: "block",
      width: "24px",
      height: "24px",
      backgroundImage:
        'url("${emojiImageUrl}")',
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    }}
  ></span>
  <span>${emoji}</span>
</span>`);
});

*/}
            
            
        </div>
        {selectedItem && (
        <OverlayPanel ref={overlayPanelRef}>
            <img src={'https://primefaces.org/cdn/primereact/images/product/bamboo-watch.jpg'} alt="Bamboo Watch"/>
          <div>Selected item: {selectedItem}</div>
        </OverlayPanel>
      )}
    </>
}

export default ChatPlaceHolder;