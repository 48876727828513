import {useContext, useState} from 'react';
import {Button} from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

interface Props {
    
}

const SignupLanding = ({ } : Props) => {
    return (
        <div className="w-full lg:w-6 p-4 lg:p-7" style={{ backgroundColor: 'rgba(255,255,255,.7)' }}>
            <img src="assets/images/blocks/logos/bastion-purple.svg" alt="Image" height="50" className="mb-6" />
            <div className="text-xl text-black-alpha-90 font-500 mb-3">Welcome to Bastion</div>
            <p className="text-black-alpha-50 line-height-3 mt-0 mb-6">Quis vel eros donec ac odio tempor orci dapibus. In hac habitasse platea dictumst quisque.</p>
            <ul className="list-none p-0 m-0">
                <li className="flex align-items-start mb-4">
                    <div>
                        <span className="flex align-items-center justify-content-center bg-purple-400" style={{ width: '38px', height: '38px', borderRadius: '10px' }}>
                            <i className="text-xl text-white pi pi-inbox"></i>
                        </span>
                    </div>
                    <div className="ml-3">
                        <span className="font-medium text-black-alpha-90">Unlimited Inbox</span>
                        <p className="mt-2 mb-0 text-black-alpha-50 line-height-3">Tincidunt nunc pulvinar sapien et. Vitae purus faucibus ornare suspendisse sed nisi lacus sed viverra. </p>
                    </div>
                </li>
                <li className="flex align-items-start mb-4">
                    <div>
                        <span className="flex align-items-center justify-content-center bg-purple-400" style={{ width: '38px', height: '38px', borderRadius: '10px' }}>
                            <i className="text-xl text-white pi pi-shield"></i>
                        </span>
                    </div>
                    <div className="ml-3">
                        <span className="font-medium text-black-alpha-90">Premium Security</span>
                        <p className="mt-2 mb-0 text-black-alpha-50 line-height-3">Scelerisque purus semper eget duis at tellus at urna. Sed risus pretium quam vulputate.</p>
                    </div>
                </li>
                <li className="flex align-items-start">
                    <div>
                        <span className="flex align-items-center justify-content-center bg-purple-400" style={{ width: '38px', height: '38px', borderRadius: '10px' }}>
                            <i className="text-xl text-white pi pi-globe"></i>
                        </span>
                    </div>
                    <div className="ml-3">
                        <span className="font-medium text-black-alpha-90">Cloud Backups Inbox</span>
                        <p className="mt-2 mb-0 text-black-alpha-50 line-height-3">Egestas sed tempus urna et. Auctor elit sed vulputate mi sit amet mauris commodo.</p>
                    </div>
                </li>
            </ul>
        </div>

    )
}

export default SignupLanding;