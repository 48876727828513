
import {useEffect, useRef, useState,useLayoutEffect, useContext} from 'react';
import { Chip } from 'primereact/chip';
import IGenericData from '@../../../../cgo_commons/types/genericData/IGenericData';
interface Props { 
    tags: IGenericData[]
}


const ChipTag: React.FC<Props> = ({tags} : Props) => {
    return <>
    {tags && tags.map((t:IGenericData,index) => {
        return <div className='px-1' key={index}><Chip label={t.description} className={t.attr1 + ' ' + t.attr2}/></div>
    })}
    </>
}

export default ChipTag;