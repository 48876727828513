import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface Props { }


const Footer: React.FC<Props> = props => {
    const [show,setShow]        = useState<boolean>(true);
    useEffect(() => {
        const urlCompleta = window.location.href;
        const rutaCompleta = urlCompleta.replace(window.location.origin, "");
        setShow(true);
        if (rutaCompleta === '/') {
            setShow(false);
        } else if (rutaCompleta.indexOf("chat") !== -1) {
            setShow(false);
        }

    },[window.location.href])
    
    return <>
    {show && 
            <div className="surface-section px-12 md:px-12 lg:px-12 mt-4">
                <div className="flex align-items-center justify-content-center">
                    <div>
                        <div className="mt-2 line-height-3">&copy; 2023 CGO</div>
                    </div>
                </div>
            </div>
    }
    </>
}

export default Footer;