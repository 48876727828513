import io, { Socket } from "socket.io-client";
interface IListener {
  [name: string]: Function;
}

export default class SocketService {
    private socket : Socket<any> | null;
    private token;
    private reconnecting:any;
    private listeners: IListener = {};
    public setUsersCount : (a:number) => void = (() => {});
    public setIsConnected : (a:boolean) => void = (() => {});
    public setReconnecting : (a:boolean) => void = (() => {});
    constructor(token : string) {
      this.token = token;
      this.socket = io(process.env.REACT_APP_URL_SOCKET_SERVER ? process.env.REACT_APP_URL_SOCKET_SERVER : '', {
        auth: {
          token: token
        },
        transports: ['websocket']
      });
      console.log("constructor")
      this.listeners = {};
    }
    public addListener(name:string,fn:Function) : void {
      this.listeners[name] = fn;
    }
    public removeListener(name:string) {
      delete this.listeners[name];
    }
    async connect() : Promise<Socket<any> | null> {
        return new Promise((resolve, reject) => {
            if (this.socket) {
                this.socket.on("connect", () => {
                    
                    this.setIsConnected(true);
                    this.setReconnecting(false);
                    //this.emit('joined', { 'serverchannel': 120 })
                    resolve(this.socket);
                  });
                  this.socket.on("connect_error", (error) => {
                    reject(error);
                  });
            }
          
        });
      }
    
      getSocket() {
        return this.socket;
      }
      emit(type:string,data:any) {
        if (this.socket) {
            this.socket.emit(type, data)
        }
        
      }
      onInstructions() {
        if (this.socket) {
            this.socket.connect();

            /*this.socket.on('connect', () => {
                this.emit('joined', { 'serverchannel': 120 })
                console.log("Connected")
                /*setIsConnected(true);
                setReconnecting(false);*/
              //})
      
              this.socket.on("private_message", (msg : any) => {
                console.log(msg)
                /*setMessages((messages : any) => [
                  ...messages,
                  { sender: msg.sender, message: msg.message },
                ]);*/
              });
    
              this.socket.on("channel_message", (obj : {sender:string,message:string}) => {
                //console.log(obj)

                if (this.listeners.hasOwnProperty('channel_message') && this.listeners['channel_message'] !== null && this.listeners['channel_message'] !== undefined) {
                  this.listeners['channel_message'](obj);
                }
              });

              this.socket.on("leave_channel_did", (obj : {channel:number}) => {
                //console.log("leave_channel_did",obj)

                if (this.listeners.hasOwnProperty('leave_channel_did') && this.listeners['leave_channel_did'] !== null && this.listeners['leave_channel_did'] !== undefined) {
                  this.listeners['leave_channel_did'](obj);
                }
              });

              this.socket.on("fn_user_connected", (obj : {connectedUsers:any}) => {
                //console.log(obj.connectedUsers)
    
              });
              this.socket.on("fn_user_channel_connected", (obj : {channel:string,connectedUsers:any}) => {
                //console.log("fn_user_channel_connected")
                //console.log(obj.connectedUsers)
                this.setUsersCount(obj.connectedUsers.length)
                //console.log(this.listeners)
                if (this.listeners.hasOwnProperty('fn_user_channel_connected') && this.listeners['fn_user_channel_connected'] !== null && this.listeners['fn_user_channel_connected'] !== undefined) {
                  this.listeners['fn_user_channel_connected'](obj);
                }
              });
              this.socket.on("typing", (obj : {channel:string,typing:any}) => {
                //console.log("typing")

                if (this.listeners.hasOwnProperty('typing') && this.listeners['typing'] !== null && this.listeners['typing'] !== undefined) {
                  this.listeners['typing'](obj);
                }
              });
              

              this.socket.on('disconnect', () => {
                this.setIsConnected(false);
          
                if (!this.reconnecting) {
                  // Intentar reconectar después de 5 segundos
                  setTimeout(() => {
                    this.setReconnecting(true);
                    this.reconnecting=true,
                    this.socket ? this.socket.connect() : ''
                  }, 5000);
                }
              });
        }
        
      }
    disconnectSocket() {
      if (this.socket) {
        this.socket.off('connect') 
        this.socket.off('disconnect')
        this.socket.off('fn_user_connected') 
        this.socket.off('fn_user_channel_connected') 
        this.socket.disconnect();
        this.socket = null;
      }
    }
  }