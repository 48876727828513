import React, { useContext, useRef, useEffect , useState, useCallback} from "react";
import { Badge } from "primereact/badge";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import {   Link, Outlet, useLocation, useNavigate, useNavigation } from "react-router-dom";
import { DeviceContext, DeviceProvider } from '../componentes/providers/DeviceProvider';
import NavLeft from "../componentes/main/NavLeft";
import AuthContext from "../componentes/authProvider/AuthContext";
import IUser from "../../../cgo_commons/types/users/IUser";
import Footer from "../componentes/main/Footer";
import ChatContext from "../componentes/chats/context/ChatContext";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import ConnectedUsersSidebar from "../componentes/chats/sidebar/ConnectedUsersSidebar";
import TypingIndicatorService from "../utils/TypingIndicatorService";
import TypingUsers from "../componentes/chats/typing/TypingUsers";
import IChannel from "@/../../cgo_commons/types/channels/IChannel";
import { LanguageContext } from "../componentes/providers/LanguageProvider";
//const  crypto = require('crypto-browserify');

interface Props { }


const Main: React.FC<Props> = ({} : Props) => {
    const { logout,isUserInfoLoaded,userLogued }                     = useContext(AuthContext);
    const {channel, setChannel,countUsers,isConnected,handleAddListener,handleRemoveListener} = useContext(ChatContext);
    const [user,setUser]                                    = useState<IUser|null>(null);
    const [isMobile, setIsMobile]                           = useState(false);
    const [visibleSidebar, setVisibleSidebar]               = useState(false);
    const [visualSwitch, setVisualSwitch]                   = useState<number>(0);//se utiliza para saber que mostrar en el top
    const [typingUsersCount, setTypingUsersCount]           = useState<IUser[]>([]);
    const [usersChannelConected, setUsersChannelConected]   = useState<{channel:string,connectedUsers:any} | null>(null);
    const { language, translations }                        = useContext(LanguageContext);
    const rutaImagen = "assets/imgs/foto-perfil.jpg";
    const imagen = require(`../${rutaImagen}`);
    const [typingIndicatorService,setTypingIndicatorService]    = useState<TypingIndicatorService>();
    const btnRef42 = useRef(null);
    const btnRef43 = useRef(null);
    const btnRef30 = useRef(null);
    const btnRefSlider = useRef<HTMLDivElement>(null);
    const {state}                                           = useLocation();
    const location                                          = useLocation();
    const navigate                                          = useNavigate();
    const handleClickLogout = () => {
        logout();
    }
    const handleShowUsers = (obj : {channel:string,connectedUsers:any}) => {
        setUsersChannelConected(obj);
    }


    
    /*const handleShowTyping = (obj: {channel:string,typing:any}) => {
        console.log("typing",obj,channel,localChannel)
        if (userLogued && (obj.channel as any) === localChannel?.id) {
            typingIndicatorService?.handleUserTyping({id:obj.typing.id,username:obj.typing.username});
            
        }
        
    }*/
    
    const handleShowTyping = (obj: { channel: string, typing: any }) => {
        if (userLogued && (obj.channel as any) === channel.id) {
          typingIndicatorService?.handleUserTyping({ id: obj.typing.id, username: obj.typing.username });
        }
      };

    useEffect(() => {
        const userAgent = navigator.userAgent;
        setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent));
        
        const navbar = document.getElementById('nav1')
        if (navbar) {
            navbar.style.position = 'sticky !important';
            navbar.style.top = '0';
            navbar.style.backgroundColor = '#fff';
            navbar.style.zIndex = '1';
            navbar.style.setProperty("position", "sticky", "important");
        }
        setTypingIndicatorService(new TypingIndicatorService(setTypingUsersCount));
        
        
        
    },[])
    useEffect(() => {
        
        if (isUserInfoLoaded) {
            setUser(userLogued);
            if (userLogued && userLogued?.age<18) {
                navigate("/inforequired");
            }
        }
    },[isUserInfoLoaded]);

    useEffect(() => {
        const path = location.pathname;
        console.log(location.pathname,state)
        if (path.startsWith("/chat") && state === null) {
            setVisualSwitch(2);//mostrar top de estar dentro de lista de channels
            navigate("/channels");
        } else if (path.startsWith("/chat")) {
            setVisualSwitch(1);//mostrar top de chat
            //setChannel((state && state.channel!=null && state.channel!=undefined && state.channel!="" ? {id:state.channel,type:state.type} as any : ''));
        } else if (path.trim() === '/' || path.trim() === '/channels') {
            setVisualSwitch(2);//mostrar top de estar dentro de lista de channels
        }
        
        
    },[state,window.location.href]);

    useEffect(() => {
        if (isConnected) {
            handleAddListener('fn_user_channel_connected',handleShowUsers);
            handleAddListener('typing',handleShowTyping);
        }
        
        return () => {
            handleRemoveListener('fn_user_channel_connected');
            handleRemoveListener('typing');
        };
    },[isConnected,channel])
    useEffect(() => {
        
        setUser(userLogued);
        if (userLogued && userLogued.id && typingIndicatorService) {
            typingIndicatorService?.setUserLogued(userLogued ? userLogued : {} as IUser);
        }
        
    },[userLogued,typingIndicatorService])

    const TopChat = () => {
        return isConnected ? (
            <>
                {channel ? 
                    <>
                        <Button value="1" onClick={() => setVisibleSidebar(true)}  >
                            <FontAwesomeIcon icon={faUsers} />
                        </Button>
                        &nbsp;{channel.title}<br></br>
                        &nbsp;<TypingUsers typingUsersCount={typingUsersCount}/>
                    </>
                    : 
                    <></>
                }
            </>
            ) : <><span>Reconectando</span> <i className="pi pi-times" style={{color:"red"}}></i></>
    }
    return (<>
    
    <DeviceProvider>
    <div className="flex relative lg:static surface-ground">
        <div id="app-sidebar-9" ref={btnRefSlider} className="h-screen surface-section hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border w-18rem lg:w-7rem select-none">
            <div className="flex flex-column h-full">
                <div className="flex align-items-center justify-content-center flex-shrink-0" style={{ height: '60px' }}>
                    <img src="/imgs/logo.svg" alt="Image" style={{maxWidth:"100px"}}/>
                </div>
                <NavLeft slider={btnRefSlider}/>
                {/*<div className="mt-auto">
                    <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
                    <a className="p-ripple m-3 flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center hover:surface-200 border-round text-600 transition-duration-150 transition-colors">
                        <img src="/imgs/logo.svg" className="mr-2 lg:mr-0" style={{ width: '32px', height: '32px' }} />
                        <span className="font-medium inline lg:hidden">Amy Elsner</span>
                        <Ripple />
                    </a>
                </div>*/}
            </div>
        </div>
        <div className="flex flex-column relative flex-auto ">
            <div id="nav1" className="navbar flex justify-content-between align-items-center px-5 surface-section relative lg:static border-bottom-1 surface-border " style={{ height: '60px',position: 'sticky',top: '0' }}>
                <div className="flex">
                    <StyleClass nodeRef={btnRef42} selector="#app-sidebar-9" enterClassName="hidden" enterActiveClassName="fadeinleft" leaveToClassName="hidden" leaveActiveClassName="fadeoutleft" hideOnOutsideClick>
                        <a ref={btnRef42} className="p-ripple cursor-pointer block lg:hidden text-700 mr-3">
                            <i className="pi pi-bars text-4xl"></i>
                            <Ripple />
                        </a>
                    </StyleClass>
                    {visualSwitch===1 && channel ? <TopChat/> : <></>}
                    
                    {visualSwitch === 2 ? (<></>) : <></>}
                </div>
                <StyleClass nodeRef={btnRef43} selector="@next" enterClassName="hidden" enterActiveClassName="fadein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                    <a ref={btnRef43} className="p-ripple cursor-pointer block lg:hidden text-700">
                        <i className="pi pi-ellipsis-v text-2xl"></i>
                        <Ripple />
                    </a>
                </StyleClass>

                <ul className="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row
    surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
                    <li className="h-full relative">
                        <StyleClass nodeRef={btnRef30} selector="@next" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                            <a ref={btnRef30}  className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                                <img src={imagen} className="lg:mr-0" style={{ width: '28px', height: '28px' }} />
                                <span className="mx-2 font-medium text-900">{user?.username}</span>
                                <i className="pi pi-angle-down"></i>
                                <Ripple />
                            </a>
                        </StyleClass>
                        <div style={{width:"200px"}} className="hidden static lg:absolute lg-w-190 surface-overlay left-0 top-100 z-1 shadow-none lg:shadow-2 origin-top border-round pl-3 lg:pl-0">
                            <div className="flex border-bottom-1 surface-border pb-4 m-1">
                                <img src={imagen} style={{ width: '70px', height: '70px' }} className="mr-3" alt="1" />
                                <div className="flex flex-column align-items-start">
                                    <span className="text-lg text-900 font-medium mb-1">{user?.username}</span>
                                    {/*<span className="text-600 font-medium mb-2">{user?.rol == 1 ? 'Administrador' : 'Usuario'}</span>*/}
                                    <span className="bg-blue-50 text-blue-400 border-round inline-flex py-1 px-2 text-sm cursor-pointer">Editar Foto</span>
                                </div>
                            </div>
                            <ul className="list-none p-0 m-0">
                                <li>
                                    <Link to="/setting" style={{ textDecoration: 'none' }}  className="p-ripple cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3">
                                        <span className="pi pi-user mr-2"></span>
                                        <span className="font-medium">{translations.my_account}</span>
                                        <Ripple />
                                    </Link>
                                </li>
                                {/*<li>
                                    <a className="p-ripple cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3">
                                        <span className="pi pi-cog mr-2"></span>
                                        <span className="font-medium">Settings</span>
                                        <Ripple />
                                    </a>
                                </li>*/}
                                <li>
                                    <a onClick={handleClickLogout} className="p-ripple cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3">
                                        <span className="pi pi-sign-out mr-2"></span>
                                        <span className="font-medium">Salir</span>
                                        <Ripple />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
                transition-duration-150 transition-colors">
                            <i className="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                            <span className="block lg:hidden font-medium">Inbox</span>
                            <Ripple />
                        </a>
                    </li>
                    <li>
                        <a className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
                transition-duration-150 transition-colors">
                            <i className="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge"><Badge severity="danger"></Badge></i>
                            <span className="block lg:hidden font-medium">Notifications</span>
                            <Ripple />
                        </a>
                    </li>
                </ul>
            </div>
            <div className="flex flex-column flex-auto">
                <div className="surface-section flex-auto" style={{padding:"5px"}}>
                    {usersChannelConected && <ConnectedUsersSidebar channel={channel} setVisibleSidebar={setVisibleSidebar} usersChannelConected={usersChannelConected} visibleSidebar={visibleSidebar}/>}
                    <Outlet />
                    <Footer/>
                </div>
            </div>
        </div>
    </div>
    </DeviceProvider>
    </>)
}
export default Main;