import {useContext, useState} from 'react';
import {Button} from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { InputText } from 'primereact/inputtext';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../../componentes/authProvider/AuthContext';
import { Message } from 'primereact/message';
import Footer from '../../componentes/main/Footer';
import IGoogleResponse from "../../../../cgo_commons/types/google/IGoogleResponse"
import { Ripple } from 'primereact/ripple';
import { Divider } from 'primereact/divider';
import { Carousel } from 'primereact/carousel';
import GoogleLogin from '../../componentes/google/GoogleLogin';
import { GoogleOAuthProvider } from '@react-oauth/google';
import FacebookLogin from '../../componentes/facebook/FacebookLogin';
import IFacebookResponse from '@/../../cgo_commons/types/facebook/IFacebookResponse';
import { LanguageContext } from '../../componentes/providers/LanguageProvider';
import userAxiosInstance from '../../componentes/axios/UserAxios';
interface Props {
    //setIsAuthenticated : (a: boolean) => void
}

function Login({ /*setIsAuthenticated */} : Props) {
  const { token ,setTokenStorage}     = useContext(AuthContext);
  const { language, translations }    = useContext(LanguageContext);
  const [checked,setChecked]          = useState<boolean>(false);
  const navigate                      = useNavigate();
  const [username, setUsername]       = useState('');
  const [password, setPassword]       = useState('');
  const [session,setSession]          = useState<number>(0);
  
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(username)
    try {
      if (username==='' && password==='') {
        setSession(-1);
        return;
      }
      const response = await fetch(process.env.REACT_APP_URL_BACKEND + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email:username,password: password })
      });

      if (response.ok) {
        const { data } = await response.json();
        if (data.token) {
          console.log(data.token)
          setTokenStorage(data.token);
          //setToken(data.token);
          navigate('/');
          setSession(1);
        } else {
          setSession(-1);
        }
        
      } else {
        console.error('Error al iniciar sesión');
        
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [loggedIn, setLoggedIn] = useState(false);

  const handleGoogleSuccess = async (token:string) => {
    const restoken = await userAxiosInstance.loginWithGoogle(token);
    //setLoggedIn(true);
    //console.log(userInfo)
    setTokenStorage(restoken);
    navigate('/');
    setSession(1);
    
  };
  const handleFacebookSuccess = async (token: string,userID:string) => {
console.log(token)
    const restoken = await userAxiosInstance.loginWithFacebook(token);

    setTokenStorage(restoken);
    navigate('/');
    setSession(1);
  };
  const handleGoogleFailure = (error: Error) => {
    console.log('Error:', error);
  };
  const handleFacebookFailure = (error: Error) => {
    console.log('Error:', error);
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //  setMessage(event.target.value);
    console.log(event.target.value);
  };

  const features = [
    { title: 'Unlimited Inbox', image: 'live-collaboration.svg', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
    { title: 'Data Security', image: 'security.svg', text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.' },
    { title: 'Cloud Backup Williams', image: 'subscribe.svg', text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.' }
];
    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLOUD_IDENTITY_API ? process.env.REACT_APP_CLOUD_IDENTITY_API : ''}>
<div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
    <div className="flex flex-wrap shadow-2">
        <div className="w-full lg:w-6 px-0 py-4 lg:p-7 bg-blue-50">
            <img src="assets/images/blocks/logos/bastion-700.svg" alt="Image" height="35" className="mb-6" />
            <Carousel value={features} itemTemplate={(feature) =>
                <div className="text-center mb-8">
                    <img src={`assets/images/blocks/illustration/${feature.image}`} alt="Image" className="mb-6 w-6" />
                    <div className="mx-auto font-medium text-xl mb-4 text-blue-900">{feature.title}</div>
                    <p className="m-0 text-blue-700 line-height-3">{feature.text}</p>
                </div>} />
        </div>
        <div className="w-full lg:w-6 p-4 lg:p-7 surface-card">
          <form onSubmit={handleSubmit}>
            <div className="flex align-items-center justify-content-between mb-7">
                <span className="text-2xl font-medium text-900">{translations.titulo_login}</span>
                <Link className="font-medium text-blue-500 hover:text-blue-700 cursor-pointer transition-colors transition-duration-150" to="/signup">{translations.sign_up}</Link>
            </div>
            <div className="flex justify-content-between">
                <FacebookLogin onSuccess={handleFacebookSuccess} onFailure={handleFacebookFailure} />
                <GoogleLogin onSuccess={handleGoogleSuccess} onFailure={handleGoogleFailure} />
            </div>
            <Divider align="center" className="my-4">
                <span className="text-600 font-normal text-sm">OR</span>
            </Divider>

            <label htmlFor="email4" className="block text-900 font-medium mb-2">Email</label>
            <InputText id="email4" type="text" placeholder="Email address" className="w-full mb-3 p-3" onChange={(e) => setUsername(e.target.value)} />

            <label htmlFor="password4" className="block text-900 font-medium mb-2">Password</label>
            <InputText id="password4" type="password" placeholder="Password" className="w-full mb-3 p-3" onChange={(e) => setPassword(e.target.value)}/>

            <div className="flex align-items-center justify-content-between mb-6">
                <div className="flex align-items-center">
                    <Checkbox id="rememberme" className="mr-2" checked={checked} onChange={(e: any) => setChecked(e.checked)} />
                    <label htmlFor="rememberme">Remember me</label>
                </div>
                <a className="font-medium text-blue-500 hover:text-blue-700 cursor-pointer transition-colors transition-duration-150">Forgot password?</a>
            </div>
            {session==-1 && <Message severity="error" className="flex  mb-3 align-items-center justify-content-center" text="Clave o usuario incorrecto" />}
            <Button label="Acceder" className="w-full py-3 font-medium" />
          </form>
        </div>
    </div>
</div>
</GoogleOAuthProvider>
    );
}

export default Login;
