import BackendResponse from "@/../../cgo_commons/axios/BackendResponse";
import getAxiosInstance from "../../utils/AxiosUtil";
import IAddress from "@/../../cgo_commons/types/response/IAddress";

class ExternalApisAxios {

    private axiosInstance : any;
    private statusOk : number;
    constructor() {
        this.axiosInstance = getAxiosInstance();
        this.statusOk = 200;
    }


    public async fetchPlacesByCoords(lat:number,lng:number,isOnlyLocality:boolean) : Promise<IAddress> {
        const obj : BackendResponse<IAddress> = await this.getAxios().get("externalApis/fetchPlacesByCoords?lat=" + lat + "&lng=" + lng + "&isOnlyLocality=" + (isOnlyLocality ? 1 : 0));
        return obj.data.data;
    }

    private getAxios() {
        if (!this.axiosInstance) {
            this.axiosInstance = getAxiosInstance();
        }
        return this.axiosInstance;
    }
}

const externalApisAxiosInstance = new ExternalApisAxios();
export default externalApisAxiosInstance;