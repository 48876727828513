import React, { createContext, useState ,useEffect} from 'react';

interface ITranslations {
  [key: string]: string;
}

interface LanguageContextProps {
  language: string;
  translations: ITranslations;
  setLanguage: (lang: string) => void;
}

export const LanguageContext = createContext<LanguageContextProps>({
  language: 'en',
  translations: {},
  setLanguage: () => {},
});

interface LanguageProviderProps {
    defaultLanguaje:string
  children: React.ReactNode;
}

const LanguageProvider: React.FC<LanguageProviderProps> = ({defaultLanguaje,children}) => {
  const [language, setLanguage]             = useState<string>(defaultLanguaje);
  const [translations, setTranslations]     = useState<ITranslations>({});
  useEffect(() => {
    // Cargar el archivo de traducción según el idioma seleccionado
    import(`../../${language}.json`)
      .then((module) => setTranslations(module.default))
      .catch((error) => console.error(`Error loading translation file for ${language}:`, error));
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, translations, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
