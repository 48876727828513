import { Ripple } from 'primereact/ripple';
import React, { useEffect, useState,useContext } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import IGoogleResponse from '@/../../cgo_commons/types/google/IGoogleResponse';
import { LanguageContext } from '../providers/LanguageProvider';
import { Button } from 'primereact/button';
interface Props {
  onSuccess: (token: string) => void;
  onFailure: (error: Error) => void;
  typeButton?:number
}

const GoogleLogin: React.FC<Props> = ({ onSuccess, onFailure,typeButton }) => {
    const [loaded,setLoaded]    = useState<boolean>(true);
    const { language, translations }                        = useContext(LanguageContext);
    /*const fetchUserInfo = (token : any) => {
        fetch('https://www.googleapis.com/oauth2/v1/userinfo?access_token=' + token)
        .then(response => response.json())
        .then((data : IGoogleResponse) => {
            onSuccess(data)
            // Aquí puedes manejar los datos que recibas de la API
        })
        .catch(error => {
            console.error(error);
            // Aquí puedes manejar cualquier error que ocurra durante la llamada a la API
        });
    }*/
    const login = useGoogleLogin({
        onSuccess: (tokenResponse: any) => onSuccess(tokenResponse.access_token)/*{fetchUserInfo(tokenResponse.access_token);}*/,
      });

  useEffect(() => {
    
  },[])
    return (<>
            {loaded && typeButton === undefined && (
            <button type="button" onClick={() => login()} className="p-ripple ml-2 w-6 font-medium border-1 surface-border surface-100 py-3 px-2 p-component hover:surface-200 active:surface-300 text-900 cursor-pointer transition-colors transition-duration-150 inline-flex align-items-center justify-content-center">
            <i className="pi pi-google text-red-500 mr-2"></i>
            <span>Sign in With Google</span>
            <Ripple />
        </button>)}

            {loaded && typeButton === 1 && (
                <Button onClick={() => login()} type="button" label={translations.sign_in_google} icon={<i className="pi pi-google text-red-500 mr-2"></i>} className="p-ripple  w-full font-medium border-1 surface-border surface-100  px-2 p-component hover:surface-200 active:surface-300 text-900 cursor-pointer transition-colors transition-duration-150 inline-flex align-items-center justify-content-center" />
            )}
        </>);
 
}

export default GoogleLogin;
