import React, { useRef, useState,useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';

interface Props {
    placeholder:string,
    visible:boolean;
    setVisible: (a:boolean) => void,
    header:string,
    content:string
 }


const InputTextAttachImg: React.FC<Props> = ({content,placeholder,visible,setVisible,header}) => {
    const contentEditableRef                    = useRef<HTMLDivElement>(null);
    const [fileContent,setFileContent]          = useState<string>("");
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const footerContent = (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

            <Button className="btn btn-primary" type="button" onClick={() => handleClickUpload()} size="small" >
                <FontAwesomeIcon icon={faImage}/>&nbsp;Subir Foto
            </Button>

            <div >
            <Button onClick={() => setVisible(false)} title='Enviar'><FontAwesomeIcon icon={faPaperPlane}/></Button>
            <Button onClick={() => setVisible(false)} title="Cancelar" severity="secondary"><FontAwesomeIcon icon={faTimes}/></Button>
            </div>
        </div>
    );

    const handleChange = () => {

    }
    const handleOnShow = () => {
        if (contentEditableRef.current) {
            contentEditableRef.current.focus();
        }
        
        if (contentEditableRef.current) {
            contentEditableRef.current.innerHTML = content;
        }
        
    }
    const handleClickUpload = () => {
        const element = document.getElementById('file_thumbnail');
        if (element) {
            element.click()
        }
        
    }

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        let reader  = new FileReader();
        let file    = (e.target && e.target.files ? e.target.files[0] : new Blob());
        let img 	= new Image();
        reader.onloadend = () => {
            img.src = reader.result as any;
            img.onload = () => {
                var canvas = document.createElement('canvas');
	            var MAX_WIDTH = 800;
      			var MAX_HEIGHT = 600;
	            // Calcular el tamaño de la imagen después de la redimensión
	            var width = img.width;
	            var height = img.height;
	            if (width > height) {
	                if (width > MAX_WIDTH) {
	                    height *= MAX_WIDTH / width;
	                    width = MAX_WIDTH;
	                }
	            } else {
	                if (height > MAX_HEIGHT) {
	                    width *= MAX_HEIGHT / height;
	                    height = MAX_HEIGHT;
	                }
	            }
	            // Redimensionar la imagen
	            canvas.width = width;
	            canvas.height = height;
	            var ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.drawImage(img, 0, 0, width, height);
                }
	            console.log(canvas)
	            // Convertir la imagen a Base64 con la calidad especificada
	            var compressedDataUrl = canvas.toDataURL('image/jpeg', 0.4);
                console.log(compressedDataUrl)
            setImagePreviewUrl(compressedDataUrl as any);
            }
            
            
          // Enviar la imagen al backend
          /*(axiosInstance !== undefined && axiosInstance.current !== undefined && (
            axiosInstance.current.post('/api/perfilCliente/upload', {image:reader.result})
              .then(() => {
                console.log("OK!")
                setShowDelImg(true)
              })
              .catch(error => {
                toastError(error.response.data.message);
              })
          ));*/
    
        };
        reader.readAsDataURL(file);
      }
    return <>
        <Dialog header={header} visible={visible}  onHide={() => setVisible(false)} footer={footerContent} onShow={handleOnShow}
        style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
            <input
                  onChange={(e) => handleFileUpload(e)}
                  id="file_thumbnail"
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  hidden
                />
            <img
            className='avatarImg'
                          id="avatarImg"
                          alt="avatar"
                          src={imagePreviewUrl}
                        />
            <div  style={{width: "calc(100% - 120px)"}}  className="p-inputtextarea p-inputtext p-component divEditable tt" 
                contentEditable="true" 
                
                ref={contentEditableRef}  
                placeholder={placeholder}
                /*onInput={(e) => handleChangeInput(e)} */
                suppressContentEditableWarning={true}>{fileContent}</div>


        </Dialog>
    </>
}
export default InputTextAttachImg;
