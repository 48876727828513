import React, { useContext, useRef, useEffect , useState} from "react";
import { Sidebar } from 'primereact/sidebar';
import { Ripple } from "primereact/ripple";
import ChatContext from "../context/ChatContext";
import IChannel from "@/../../cgo_commons/types/channels/IChannel";
//const  crypto = require('crypto-browserify');

interface Props { 
    visibleSidebar:boolean;
    setVisibleSidebar: (a:boolean) => void;
    channel:IChannel
    usersChannelConected:{channel:string,connectedUsers:any} | null;
}


const ConnectedUsersSidebar: React.FC<Props> = ({visibleSidebar,setVisibleSidebar,channel,usersChannelConected} : Props) => {
    const { setHandleWriteText} = useContext(ChatContext);

    const handleClickContact = (t:any) : void => {
        setHandleWriteText(t);
        setVisibleSidebar(false)
    }

    useEffect(() => {
        setHandleWriteText(null);
    },[visibleSidebar])
    return <>
    <Sidebar visible={visibleSidebar} onHide={() => setVisibleSidebar(false)} >
        <div className="m-2">
            <p>Canal {channel.title}</p>
        </div>
        
        <div className="flex-auto overflow-y-auto">
            <ul className="list-none p-0 m-0">
                                
                {usersChannelConected && usersChannelConected.connectedUsers && usersChannelConected.channel === (channel.id as any) && usersChannelConected.connectedUsers.map((t:any,index:number) => 
                    (

                        <li className="mb-2" style={{ position: 'relative' }} key={index} onClick={(e) => handleClickContact(t)}>
                            <a className="p-ripple flex align-items-center py-1 px-2 cursor-pointer hover:surface-200 transition-colors transition-duration-150">
                                <img
                                    src={`${process.env.REACT_APP_URL_BACKEND}/img/get/-1/avatar-f-3.png`}
                                    className="mr-3 flex-shrink-0"
                                    style={{
                                        width: "50px",
                                        height: "50px",
                                    }}
                                />
                                <div>
                                    <span className="text-900 font-medium mb-2">
                                        {t.username}
                                    </span>
                                    <p className="m-0 text-600 text-sm">
                                        Bank of Prime
                                    </p>
                                    <div style={{ position: 'absolute', top: '5px', right: '10px', fontSize: '12px', color: 'gray' }}>
      10:30am
    </div>
                                </div>
                                <i className="ml-auto mt-3 pi pi-chevron-right text-700"></i>
                                <Ripple />
                            </a>
                        </li>
                        
                    )
                )}
            </ul>
        </div>
    </Sidebar>
    </>
}

export default ConnectedUsersSidebar;