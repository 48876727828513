import { Ripple } from 'primereact/ripple';
import React, { MouseEventHandler, useEffect, useState,useContext } from 'react';
import { FacebookLoginClient } from '@greatsumini/react-facebook-login';
import IFacebookResponse from '../../../../cgo_commons/types/facebook/IFacebookResponse'
import { LanguageContext } from '../providers/LanguageProvider';
import { Button } from 'primereact/button';
interface Props {
    onSuccess : (a:string,b:string) => void;
    onFailure : (a:any) => void;
    typeButton?:number;
    style?: React.CSSProperties;
}

const FacebookLogin: React.FC<Props> = ({ onSuccess, onFailure,typeButton,style }) => {
    const [loaded,setLoaded]                = useState<boolean>(true);
    const { language, translations }        = useContext(LanguageContext);
    const appId  = process.env.REACT_APP_FACEBOOK_API ? process.env.REACT_APP_FACEBOOK_API : ''
    const login = (event:React.MouseEvent<HTMLButtonElement>) => {
        if (event.clientX >0 && event.clientY>0) {
            FacebookLoginClient.login(successLogin, {
                scope: 'public_profile, email',
              });
        }
        
    }
    const successLogin = (info: any) => {
        const res : {authResponse:{accessToken: string,data_access_expiration_time: number,expiresIn: number,graphDomain: string,signedRequest: string,userID: string}} = info;
        onSuccess(res.authResponse.accessToken,res.authResponse.userID);
    }
   
    const loadFB = async () => {
        FacebookLoginClient.clear();
        await FacebookLoginClient.loadSdk('es_ES');
        FacebookLoginClient.init({ appId:appId,version    : 'v16.0' });
      };
    useEffect(() => {
        loadFB();
    }, []);

    return (<>
            {loaded && typeButton !== 1 && (
                <button onClick={login} className="p-ripple mr-2 w-6 font-medium border-1 surface-border surface-100 py-3 px-2 p-component hover:surface-200 active:surface-300 text-900 cursor-pointer transition-colors transition-duration-150 inline-flex align-items-center justify-content-center">
                    <i className="pi pi-facebook text-indigo-500 mr-2"></i>
                    <span>Sign in With Facebook</span>
                    <Ripple />
                </button>
            )}
            {loaded && typeButton === 1 && (
                <div style={style}>
                    <Button onClick={login} type="button" label={translations.sign_in_facebook} icon={<i className="pi pi-facebook text-indigo-500 mr-2"></i>} className="p-ripple  w-full font-medium border-1 surface-border surface-100  px-2 p-component hover:surface-200 active:surface-300 text-900 cursor-pointer transition-colors transition-duration-150 inline-flex align-items-center justify-content-center" />
                </div>
            )}
        </>);
 
}

export default FacebookLogin;
