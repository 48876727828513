import { faFaceSmile, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import data from '@emoji-mart/data/sets/14/apple.json'
import Picker from '@emoji-mart/react'
import { Button } from 'primereact/button';
import React, { useRef, useState,useContext,useEffect } from 'react';
import { useClickOutside } from 'primereact/hooks';
import InputTextAttachImg from '../modals/InputTextAttachImg';
import { DeviceContext } from '../../../componentes/providers/DeviceProvider';
import ChatContext from '../context/ChatContext';

interface Props {
    placeholder:string,
    adjustHeight:(a?:number) => void,
    handleSendMessage : (message : string) => void
    handleTyping: () => void;
 }


const ChatInputText: React.FC<Props> = ({placeholder,adjustHeight,handleSendMessage,handleTyping}) => {
    const contentEditableRef                    = useRef<HTMLDivElement>(null);
    const [content,setContent]                  = useState<string>("");
    const [emojisPerLine,setEmojisPerLine]      = useState<number>();
    const [showEmoji,setShowEmoji]              = useState<boolean>(false);
    const [hasChanged,setHasChanged]            = useState<boolean>(false);
    const [showAttach,setShowAttach]            = useState<boolean>(false);
    const overlayRef                            = useRef<HTMLDivElement>(null);
    const {isMobile}                            = useContext(DeviceContext);
    const { handleWriteText}                    = useContext(ChatContext);
    let curPos : any;
    const handleChangeInput = (e: React.FormEvent<HTMLDivElement>) => {
        setHasChanged(true)
        adjustHeight();
    }
    useClickOutside(overlayRef, () => {
        setShowEmoji(false);
        adjustHeight();
    });
    useEffect(() => {
        if (handleWriteText && contentEditableRef.current) {
            contentEditableRef.current.innerHTML = handleWriteText.username + ':&nbsp;' + contentEditableRef.current.innerHTML
            setTimeout(() => {
              contentEditableRef.current?.focus();
              const range = document.createRange();
              const selection = window.getSelection();
              if (contentEditableRef.current && selection) {
                range.selectNodeContents(contentEditableRef.current);
                range.collapse(false);
                selection.removeAllRanges();
                selection.addRange(range);
              }
            }, 0);
          }
    },[handleWriteText])
    const addEmoji = (e: any) => {
        let emoji = e.native;

        const selection = window.getSelection();

        console.log((selection ? selection.type : "") +" -- " + hasChanged)
        if ((selection && selection.type === 'Caret' && !hasChanged)) {
            setContent(content + emoji);
        } else if ((selection && selection.type !== "None")) {
            console.log(selection)
            let range ;
            range = selection.getRangeAt(0);

            
            
            if (contentEditableRef && contentEditableRef.current && contentEditableRef.current.innerHTML) {
                if (contentEditableRef.current.contains(range.commonAncestorContainer)) {
                    console.log(range)
                    curPos = range.cloneRange();
                    console.log(curPos)
                    range.insertNode(document.createTextNode(emoji));
                    range.collapse(false);
                    contentEditableRef.current?.focus();

                    let newContent = contentEditableRef.current.innerHTML;
                    const search = '&nbsp;';
                    const replaceWith = '';
                    newContent = newContent.replaceAll(search, replaceWith);
                    //setContent(newContent);
                    //contentEditableRef.current.dispatchEvent(new Event("input", { bubbles: true }));
                    //selection.removeAllRanges();
                    console.log(contentEditableRef.current.innerHTML.replaceAll(search, replaceWith))
                    //selection.addRange(curPos);
            
                    //restoreCursorPosition();
                }
                
            }
            
        } else {
            setContent(content + emoji);
        }
      };
      const handleEmojis = (a:boolean) => {
        setShowEmoji(a);
        if (a) {
            setTimeout(function () {
                adjustHeight(overlayRef.current?.offsetHeight)
            },80)
            
        }
        
      }
      const handlePaste = (e:any) => {
        e.preventDefault(); // Detener el pegado automático de HTML
        const text = e.clipboardData.getData('text/plain'); // Obtener el texto plano
        document.execCommand('insertText', false, text); // Insertar el texto en el div
      }
      const handleLocalSendMessage = (message:string) => {
        handleSendMessage(message);
        if (contentEditableRef.current) {
            //contentEditableRef.current.innerHTML='';
        }
        
      }
    return <>
    <div className='inputContainer' id='inputContainer'>
        <div  style={isMobile ? {width: "calc(100% - 82px)"} : {width: "calc(100% - 120px)"}}  
                id="chat_input_text"
                className="p-inputtextarea p-inputtext p-component divEditable" 
                contentEditable="true" 
                ref={contentEditableRef}  
                placeholder={placeholder} 
                onInput={(e) => handleChangeInput(e)} 
                suppressContentEditableWarning={true}
                onKeyDown={handleTyping}
                onPaste={(e: React.ClipboardEvent<HTMLDivElement>) => handlePaste(e)} 
                >{content}</div>
        <div>
        <Button style={{padding:"10px",marginLeft:"4px"}} onClick={(e) => handleLocalSendMessage(contentEditableRef.current ? contentEditableRef.current?.innerText : '')} ><FontAwesomeIcon icon={faPaperPlane} /></Button>
        <Button style={{padding:"10px",marginLeft:"4px"}} onClick={(e) => setShowAttach(true)}><i className="pi pi-paperclip"></i></Button>
        {!isMobile && <Button style={{padding:"10px",marginLeft:"4px"}} onClick={(e) => {handleEmojis(true)}}><FontAwesomeIcon icon={faFaceSmile}  /></Button>}
        
        </div>
    </div>
    <InputTextAttachImg visible={showAttach} setVisible={setShowAttach} header="Publicar Imagen" content={(contentEditableRef.current ? contentEditableRef.current.innerHTML : '')} placeholder='Escribir un comentario para la foto...' />
    {showEmoji && 
            <div ref={overlayRef} >
                <Picker data={data} set="apple" onEmojiSelect={(e: any) => addEmoji(e)} perLine={emojisPerLine}/>
            </div>
    }
    </>
}

export default ChatInputText;