import IChannel from "@/../../cgo_commons/types/channels/IChannel";
import AuthContext from "../../../componentes/authProvider/AuthContext";
import SocketService from "../../../utils/SocketService";
import React, { createContext, useEffect, useState,useContext } from "react";
import { ReactNode } from 'react';
import { Socket } from "socket.io-client";


interface ChatContextData {
    setCountUsers: (a:number) => void;
    setChannel: (a:IChannel) => void;
    countUsers: number;
    channel: IChannel;
    socket:Socket<any> | null;
    isConnected:boolean;
    reconnecting:boolean;
    handleAddListener : (name:string,fn : Function) => void;
    handleRemoveListener: (name:string) => void;
    setHandleWriteText:(data:any) => void;
    handleWriteText:any;
  }
  interface ChatProviderProps {
    children: ReactNode;
  }
  const ChatContext = createContext<ChatContextData>({
    setCountUsers: (a : number) => {},
    setChannel:(a : IChannel) => {},
    countUsers: 0,
    channel:{} as any,
    socket:null,
    isConnected:false,
    reconnecting:false,
    handleAddListener:() => {},
    handleRemoveListener: () => {},
    setHandleWriteText: (a:any) => {},
    handleWriteText:null,
  });
  
  export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
    const { logout,userLogued,token }     = useContext(AuthContext);
    const [countUsers, setCountUsers]       = useState<number>(0);
    const [channel,setChannel]              = useState<IChannel>({} as IChannel);
    const [isConnected, setIsConnected]     = useState<boolean>(false);
    const [socketService,setSocketService]  = useState<SocketService | null>();
    const [reconnecting, setReconnecting]   = useState(false);
    const [usersCount,setUsersCount]        = useState<number | null>(null);
    const [socket, setSocket]                       = useState<Socket<any> | null>(null);
    const [socketInitialized,setSocketInitialized]  = useState<boolean>(false);
    const [handleWriteText,setHandleWriteText]  = useState<any | null>();
    async function initializeSocket() {
      const service  = new SocketService(token ? token : '');
      setSocketService(service);
      service.setIsConnected = setIsConnected;
      service.setReconnecting = setReconnecting;
      service.setUsersCount = setUsersCount;
      try {
        const connectedSocket = await service.connect();
        setSocket(connectedSocket);
        service.onInstructions();
      } catch (error) {
        console.error("Error connecting to socket", error);
      }
    }

    useEffect(() => {
      if (userLogued && userLogued.id!=null && userLogued.id!=undefined && !socketInitialized) {
        initializeSocket();
        setSocketInitialized(true)
      }
        
    },[userLogued,socketInitialized])

    const handleAddListener = (name:string,fn : Function) : void => {
      console.log("handleAddListener: " + name)
      socketService?.addListener(name,fn);
    }
    const handleRemoveListener = (name:string) : void => {
      socketService?.removeListener(name);
    }
    useEffect(() => {
      console.log("chatcontest channel modif" + channel)
    },[channel])
    useEffect(() => {
      

      return () => {
        socketService?.disconnectSocket();
      };
    }, []);
    return (
      <ChatContext.Provider value={{ setCountUsers, setChannel,countUsers,channel,socket,isConnected,reconnecting,handleAddListener,handleRemoveListener,setHandleWriteText,handleWriteText}}>
        {children}
      </ChatContext.Provider>
    );
  };
  
  export default ChatContext;