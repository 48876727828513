export const genderOptions = () => {

    const list = [
        {id:1,text:"Masculino"},
        {id:2,text:"Femenino"},
        {id:3,text:"Indefinido"}
    ]

    return list;
}
    
export const sexualPreferencesOptions = () => {

    const list = [
        {id:1,text:"Hombres"},
        {id:2,text:"Mujeres"},
        {id:3,text:"Indefinido"}
    ]

    return list;
}
