import {useContext, useState,useEffect,useRef} from 'react';


import { InputText } from 'primereact/inputtext';
import useGooglePlaceAutoComplete from '../../utils/useGooglePlaceAutoComplete';
import IAddress from '@/../../cgo_commons/types/response/IAddress';
import { isNull } from '../../utils/Utils';


interface Props {
    setLocacion: (a : IAddress) => void;
    googleAutoComplete:any;
    location: IAddress | undefined;
}


const InputAutocomplete : React.FC<Props> = ({location,setLocacion,googleAutoComplete} ) => {
    const addressRef                                   = useRef<HTMLInputElement | null>();
    const [error,setError]                             = useState<boolean>(false);
    //const [place,setPlace]                              = useState<IAddress>();
    
    let autoComplete = "";
    const handleAddressSelect = async () => {
        let addressObj : IAddress = await googleAutoComplete.getFullAddress(autoComplete);
        console.log(addressObj)
        //address1Ref.current.value = addressObj.address1;
        if (!isNull(addressObj.city)) {
            setLocacion(addressObj);
        } else {

        }
        
        //setPlace(addressObj);
        /*
        setValue("country", addressObj.country);
        setValue("state",addressObj.state)*/
    };

    useEffect(() => {
        async function loadGoogleMaps() {
            // initialize the Google Place Autocomplete widget and bind it to an input element.
            // eslint-disable-next-line
            autoComplete = await googleAutoComplete.initAutoComplete(addressRef.current as any, handleAddressSelect);
        }
        if (googleAutoComplete.scriptLoaded) {
            loadGoogleMaps();
        }
        return undefined;
    }, [googleAutoComplete.scriptLoaded]);

    useEffect(() => {
        if (addressRef.current && location) {
            addressRef.current.value = (location?.city ? location.city : '' ) + ', ' + location?.state + ", " + location?.country;
        }
        
    },[location])
    return <>
        <div className="flex-auto p-fluid">
            <div className="">
                <label
                    htmlFor="ubicacion"
                    className="block font-normal text-900 mb-2"
                >
                    Ciudad donde vives
                </label>
                <InputText id="ubicacion" ref={addressRef as any} placeholder='Ciudad donde vives'/>
            </div>
            {error && <span style={{color:"red"}}>Ciudad no encontrada</span>}
            {location && <>
                <div className="mb-2 mt-2">
                    <b>Ciudad</b>: {location.city}
                </div>
                <div className="mb-2">
                    <b>Provincia</b>: {location.state}
                </div>
                <div className="mb-2">
                    <b>Pais</b>: {location.country}
                </div>
            </>}
        </div>
    </>
}

export default InputAutocomplete;