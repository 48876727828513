import React, { useEffect, useRef, useState } from "react";
import IAddress from '../../../cgo_commons/types/response/IAddress';


interface GooglePlaceAutoComplete {
  initAutoComplete: (input: HTMLInputElement, callback: () => void) => Promise<any | null>;
  getFullAddress: (autoComplete: any) => Promise<IAddress>;
  scriptLoaded: boolean;
}

const useGooglePlaceAutoComplete = (key: string, isOnlyLocality: boolean): GooglePlaceAutoComplete => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const scriptRef = useRef<HTMLScriptElement | null>(null);
  const bsAs = "Buenos Aires".toUpperCase();

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    const loadGoogleMaps = () => {
      // Cargar el script solo si no se ha cargado antes
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`;
      script.async = true;
  
      script.onload = () => {
        setScriptLoaded(true);
      };
  
      document.body.appendChild(script);
    };
  
    if (!scriptLoaded) {
      loadGoogleMaps();
    }
  
    return () => {
      // Eliminar el script cuando se desmonte el componente
      const script = document.querySelector(`script[src="https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places"]`);
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [scriptLoaded]);
  const initAutoComplete = async (input: HTMLInputElement, callback: () => void): Promise<any | null> => {
    let autoComplete: any | null = null;
    if (scriptLoaded) {
      autoComplete = new (window as any).google.maps.places.Autocomplete(input, {
        componentRestrictions: { country: ["AR", "BO", "BR", "CL", "CO", "CR", "CU", "DO", "EC", "GT", "HN", "HT", "JM", "MX", "NI", "PA", "PE", "py", "SV", "UY", "VE"] },
        fields: ["address_component", "formatted_address","geometry"],
        types: ['locality']
      });
      autoComplete.addListener("place_changed", callback);
    }
    return autoComplete;
  };

  const getFullAddress = async (autoComplete: any): Promise<IAddress> => {
    const place = autoComplete.getPlace();

    let address1 = "";
    let locality = "";
    let adminArea1Short = "";
    let adminArea1Long = "";
    let countryShort = "";
    let countryLong = "";
    let postalCode = "";

    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    for (const component of place.address_components) {
      const componentType = component.types[0];

      if (componentType === "street_number") {
        address1 = component.long_name;
      }
      if (componentType === "route") {
        address1 = `${address1} ${component.long_name}`;
      }
      if (componentType === "locality") {
        locality = component.long_name;
      }
      if (componentType === "administrative_area_level_1") {
        adminArea1Short = component.short_name;
        adminArea1Long = component.long_name;
      }
      if (componentType === "postal_code") {
        postalCode = component.long_name;
      }
      if (componentType === "postal_code_suffix") {
        postalCode = `${postalCode}-${component.long_name}`;
      }
      if (componentType === "country") {
        countryShort = component.short_name;
        countryLong = component.long_name;
      }
    }

    const resAddress: IAddress = isOnlyLocality
      ? {
          city: locality.toUpperCase() === bsAs && adminArea1Short === "CABA" ? 'Ciudad de Buenos Aires' : locality,
          cityShort: adminArea1Short,
          state: adminArea1Long,
          countryShort: countryShort,
          country: countryLong,
          lat,
          lng
        }
      : {
          address1,
          city: locality,
          cityShort: adminArea1Short,
          state: adminArea1Long,
          postalCode,
          countryShort: countryShort,
          country: countryLong,
          lat,
          lng
        };

    return resAddress;
  };

  return {
    initAutoComplete,
    getFullAddress,
    scriptLoaded
  };
};

export default useGooglePlaceAutoComplete;
