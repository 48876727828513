import IUser from "@/../../cgo_commons/types/users/IUser";
import React, { useEffect , useState} from "react";

//const  crypto = require('crypto-browserify');

interface Props {
    typingUsersCount:IUser[]
 }


const TypingUsers: React.FC<Props> = ({typingUsersCount} : Props) => {
    const [strUsers,setStrUsers]            = useState<string>('');
    useEffect(() => {
        if (typingUsersCount.length>0) {
            let str = '';
            typingUsersCount.forEach ((t,index) => {
                str += t.username + ", "
            })
            str = str.slice(0, -2);
            str += " escribiendo";
            
            setStrUsers(str);
        } else {
            setStrUsers('');
        }
    },[typingUsersCount])
    return <>
        {strUsers}
    </>
}

export default TypingUsers;