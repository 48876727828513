import React, { useEffect } from 'react';
import { differenceInSeconds } from './Utils';

export default class TypingQueue {
    private time : Date | null;
    private sendTyping:Function;
  constructor(fn: Function) {
    this.time = null;
    this.sendTyping = fn;
  }

  sendTypingEvent() {
    // Enviar el evento "userTyping" al servidor aquí
    // Usar this.typingQueue para enviar todos los eventos pendientes
    console.log('Enviando evento "userTyping":');
    this.sendTyping();
    this.time = new Date();
  }

  addToTypingQueue() {
    if (this.time == null || differenceInSeconds(this.time,new Date())>2) {
        this.time = new Date();
        this.sendTypingEvent();
    }

  }

  
}