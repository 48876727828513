import IUser from '@/../../cgo_commons/types/users/IUser';
import { differenceInSeconds } from './Utils';

interface TypingData extends IUser{
  lastEventTime: Date;
}

interface UserTyping {
  id: number;
  username: string;
}

export default class TypingIndicatorService {
    private typingUsers: TypingData[] = [];
    private handleUpdateList: Function;
    private clearTimeoutId : any;
    private userLogued : IUser | null;
    constructor(fn:Function) {
      this.handleUpdateList = fn;
      this.clearTimeoutId   = null;
      this.userLogued       = null;
    }
    setUserLogued(userLogued:IUser) {
      this.userLogued = userLogued;
    }
    handleUserTyping = (data: UserTyping) => {
      const { id, username } = data;
      console.log("typing",this.userLogued,id)
      if (this.userLogued && this.userLogued.id !== id) {
        // Verificar si el usuario ya existe en la lista
        const userIndex = this.typingUsers.findIndex(user => user.id === id);
        if (userIndex !== -1) {
          // Actualizar el tiempo del último evento de escritura del usuario
          this.typingUsers[userIndex].lastEventTime = new Date();
        } else {
          // Agregar un nuevo usuario a la lista
          this.typingUsers.push({ id, username, lastEventTime: new Date() } as any);
        }
        console.log("pasatiping",this.typingUsers)
        // Eliminar a los usuarios que han pasado más de 2 segundos sin eventos de escritura
        this.refresh();
        if (this.clearTimeoutId) {
          clearTimeout(this.clearTimeoutId);
        }

        this.clearTimeoutId = setTimeout(() => {
          this.refresh();
        }, 4000);
      }
      
    };

    refresh () {
      this.typingUsers = this.typingUsers.filter(user => differenceInSeconds(new Date(), user.lastEventTime) <= 2);
      this.handleUpdateList(this.typingUsers);
    }
    getTypingUsers = () => this.typingUsers;
}
