import IChannel from "../../../../cgo_commons/types/channels/IChannel";
import getAxiosInstance from "../../utils/AxiosUtil";
import BackendResponse from '../../../../cgo_commons/axios/BackendResponse'
import UpdateFields from "@/../../cgo_commons/types/genericData/UpdateFields";
import ChannelFilter from '../../../../cgo_commons/filters/channels/ChannelFilter'
class ChannelAxios {

    private axiosInstance : any;
    private statusOk : number;
    constructor() {
        this.axiosInstance = getAxiosInstance();
        this.statusOk = 200;
    }

    /*public async createUser(user : IUser) : Promise<IUser> {
        try {
            const result : BackendResponse | any = await this.getAxios().post("/login/create",user);
        
            if (result.status != this.statusOk) {
                console.log(result.result)
                throw new Error(result.errorResponse?.message);
            }
            return result.data.data;
        } catch (e : any) {
            throw new Error(e.response.data.errorResponse.message);
        }
        
    }*/

    public async createUpdate(channel: IChannel): Promise<IChannel> {
        return new Promise<IChannel>((resolve, reject) => {
          this.getAxios()
            .post("/api/channel/save", channel)
            .then((result: BackendResponse<IChannel>) => {
              if (result.status !== this.statusOk) {
                reject(result.errorResponse?.message);
              } else {
                resolve(result.data.data);
              }
            })
            .catch((e: any) => {
              reject(e.response.data.errorResponse.message);
            });
        });
      }
    
    public async fetch(filter : ChannelFilter) : Promise<{data:IChannel[]}> {
        try {
            const result : BackendResponse<IChannel[]> | any = await this.getAxios(true).post("/api/channel/fetch" ,filter);

            if (result.status != this.statusOk) {
                throw new Error(result.errorResponse?.message);
            }
            return result.data.data;
        } catch (e : any) {
            throw new Error(e.response.data.errorResponse.message);
        }
        
    }
    public async delete(id : number) : Promise<void> {
        try {
            if (id === null || id<1) {
                throw new Error("Id incorrecto");
            }
            await this.getAxios().delete("/api/channel/" + id);
        
        } catch (e : any) {
            console.error(e)
            throw new Error(e.response.data.errorResponse.message);
        }
    }


    private getAxios(validateToken?: boolean | null) {
        if (!this.axiosInstance) {
            this.axiosInstance = getAxiosInstance();
        }
        if (validateToken && !this.axiosInstance.hasToken) {
            this.axiosInstance = getAxiosInstance();
        }
        return this.axiosInstance;
    }
}

const channelAxiosInstance = new ChannelAxios();
export default channelAxiosInstance;